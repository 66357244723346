import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import { ROUTES } from "config/constants";
import {
  classModifier,
} from 'utils';

import './Navbar.scss';
import ICONS from 'assets/icons';

const getLinkClassName = ({ isActive }) => {
  return isActive 
    ? 'navbar__link navbar__link--active'
    : 'navbar__link';
}

const Navbar = () => {
  const isSalesPageOpen = useSelector((state) => state.user.isSalesPageOpen);
  const isMailPageOpen = useSelector((state) => state.user.isMailPageOpen);
  const isAdrBookPageOpen = useSelector((state) => state.user.isAdrBookPageOpen);
  const isCalendarPageOpen = useSelector((state) => state.user.isCalendarPageOpen);
  const location = useLocation();

  return (
    <nav className="navbar">
      <ul className="navbar__list">
        <li className="navbar__item">
          <NavLink to={ROUTES.main} className={getLinkClassName}>
            <ICONS.comments className="navbar__icon"/>
          </NavLink>
        </li>

        <li
          className={classModifier('navbar__item', [
            isSalesPageOpen && 'disabled',
            location.pathname === ROUTES.sales && 'active',
          ])}
        >
          <NavLink
            to={ROUTES.sales}
            target="_blank"
            className={getLinkClassName}
            rel="noreferrer"
          >
            <ICONS.puzzle className="navbar__icon"/>
          </NavLink>
        </li>

        <li
          className={classModifier('navbar__item', [
            isAdrBookPageOpen && 'disabled',
            location.pathname === ROUTES.adrBook && 'active',
          ])}
        >
          <NavLink
            to={ROUTES.adrBook}
            target="_blank"
            className={getLinkClassName}
            rel="noreferrer"
          >
            <ICONS.adrBook className="navbar__icon"/>
          </NavLink>
        </li>

        <li
          className={classModifier('navbar__item', [
            isMailPageOpen && 'disabled',
            location.pathname === ROUTES.mail && 'active',
          ])}
        >
          <NavLink
            to={ROUTES.mail}
            target="_blank"
            className={getLinkClassName}
            rel="noreferrer"
          >
            <ICONS.mail className="navbar__icon"/>
          </NavLink>
        </li>

        <li
          className={classModifier('navbar__item', [
            isCalendarPageOpen && 'disabled',
            location.pathname === ROUTES.calendar && 'active',
          ])}
        >
          <NavLink
            to={ROUTES.calendar}
            target="_blank"
            className={getLinkClassName}
            rel="noreferrer"
            replace
          >
            <ICONS.calendar className="navbar__icon"/>
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar;