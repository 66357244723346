import API from 'api/api';

export const GET_CLIENTS_TEMPLATES = 'GET_CLIENTS_TEMPLATES';
export const GET_GIRLS_TEMPLATES = 'GET_GIRLS_TEMPLATES';
export const GET_ALL_TEMPLATES = 'GET_ALL_TEMPLATES';
export const GET_MSG_SHORTCUTS = 'GET_MSG_SHORTCUTS';


export const getAllTemplates = () => dispatch => {
  API.getAllTemplates()
    .then((({ clientsTemplates, girlsTemplates }) => {
      dispatch({
        type: GET_ALL_TEMPLATES,
        payload: {
          clientsTemplates: clientsTemplates.data,
          girlsTemplates: girlsTemplates.data
        }
      })
    }))
    .catch(err => console.log(err));
}

export const getMsgShortcuts = () => dispatch => {
  API.getMsgShortcuts()
    .then(res => {
      if (!res.data.length) return;

      dispatch({
        type: GET_MSG_SHORTCUTS,
        payload: res.data
      })
    })
    .catch(err => console.log(err))
}

const formatShortcuts = (shortcuts) => {
  const result = {};

  shortcuts.forEach(({ value, id, shortcut }) => {
    result[shortcut] = { id, value }
  });

  return result;
}


const initialState = {
  clientsTemplates: [],
  girlsTemplates: [],
  shortcuts: {}
  // 'thk': { id: 1, value: 'Thank you' },
  // 'gb': { id: 2, value: 'Good by' },
  // '1hr': { id: 3, value: 'Are you available for 1 incall, please?' }
  // }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TEMPLATES: {
      return {
        ...state,
        girlsTemplates: action.payload.girlsTemplates,
        clientsTemplates: action.payload.clientsTemplates
      }
    }
    case GET_CLIENTS_TEMPLATES: {
      return {
        ...state,
        clientsTemplates: action.payload
      }
    }
    case GET_GIRLS_TEMPLATES: {
      return {
        ...state,
        girlsTemplates: action.payload
      }
    }
    case GET_MSG_SHORTCUTS: {
      return {
        ...state,
        shortcuts: formatShortcuts(action.payload)
      }
    }
    default:
      return state;
  }
}
