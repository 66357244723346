import React, { useEffect, useState } from 'react';
import { classModifier } from 'utils';
import { usePrevious } from 'hooks';
import { CSSTransition } from 'react-transition-group';
import components from 'containers/Dialogs/components';

import { MODAL_TYPES } from 'redux/ducks/activeWindows';

import './Modal.scss';


const modalTypesWithoutClosingOnOutsideClick = [
  MODAL_TYPES.bookingEditForm,
  MODAL_TYPES.bookingForm,
]

const modalTypesWithSpecificAnimation = [
  MODAL_TYPES.photoGallery
]


const Modal = (props) => {
  const [lastModalNumber, setLastModalNumber] = useState(0);
  const [entities, setEntities] = useState(props.children);
  const prevEntities = usePrevious(entities);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    }
  }, [props.closeModal, props.isLastModal, props.notCloseOnEscape, lastModalNumber])

  const handleKeyDown = (e) => {
    if (!props.closeModal) return;
    if (props.notCloseOnEscape) return;

    if (e.code === "Escape" && props.isLastModal) {
      lastModalNumber > 0 ? props.closeChildModal() : props.closeModal();
    }
  }

  const handleMouseDown = (e) => {
    if (!props.closeModal) return;
    if (props.notCloseOutside) return;
    if (modalTypesWithoutClosingOnOutsideClick.includes(props.modalType)) return;

    if (e.target !== e.currentTarget) {
      return;
    }

    props.closeModal();
  }

  const activeClass = props.isOpen
    ? 'modal-container open'
    : 'modal-container open out';

  useEffect(() => {
    setTimeout(() => setLastModalNumber(props.childCount), 0);
  }, [props.children])

  useEffect(() => {
    if (prevEntities.length > props.children.length) {
      setEntities((prevEntities) => ([
        ...prevEntities.slice(0, prevEntities.length - 1),
        null
      ]))
      setTimeout(() => setEntities((prevEntities) => prevEntities.slice(0, prevEntities.length - 1)), 300)
    } else {
      setEntities(props.children)
    }
  }, [props.children])

  const cssTransactionInClause = (index) => {
    if (index === 0) return index === props.childCount;

    return index === lastModalNumber;
  }

  const renderComponent = (modal) => {
    if (!modal?.type) return <></>;

    const Component = components[modal.type];

    return (
      <Component
        closeModal={props.closeModal}
        closeChildModal={props.closeChildModal}
        dispatchCloseEvent={props.dispatchCloseEvent}
        {...props.commonProps}
        {...modal.props}
      />
    )
  }

  const getTransitionClass = (index) => {
    if (index === 0) {
      return modalTypesWithSpecificAnimation.includes(props.modalType) ? `modal-content__primary--${props.modalType}` : `modal-content__primary`;
    } else {
      return modalTypesWithSpecificAnimation.includes(props.modalType) ? `modal-content__next--${props.modalType}` : `modal-content__next`;
    }
  }

  return (
    <div
      className={activeClass}
      style={props.zIndex ? { zIndex: props.zIndex } : null}
    >
      <div
        className={classModifier('modal-background', [
          !!props.backgroundClassModifier && props.backgroundClassModifier,
        ])}
        onMouseDown={handleMouseDown}
      >
        <article className={classModifier("modal-content", [
          !!props.backgroundClassModifier && props.backgroundClassModifier,
        ])}>
          {/* <Scrollbars > */}
          {entities.map((modal, index) => (
            <CSSTransition
              key={index}
              in={cssTransactionInClause(index)}
              timeout={300}
              classNames={getTransitionClass(index)}
            >
              {renderComponent(modal)}
            </CSSTransition>
          ))}
          {/* </Scrollbars> */}
        </article>
      </div>
    </div>
  )
}

export default Modal;
