import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import API from 'api/api';
import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { MODAL_TYPES } from 'redux/ducks/activeWindows';
import { setGirlsTabsInBuffer } from 'redux/ducks/girlChats';
import { closeModal, openModal } from 'redux/ducks/activeWindows';
import { removeContactTab, updateActiveContact } from 'redux/ducks/clientChats';
import { selectBookingForBufferChat, selectContactsByDivaId } from 'redux/selectors/selectors';

import './BufferChats.scss';
import BufferSearch from './BufferSearch';
import GirlChat from 'containers/Chat/GirlChat';
import ClientChat from 'containers/Chat/ClientChat';


const BufferChats = ({ profilesContactIds }) => {
  const bufferContacts = useSelector(state => state.girlChats.bufferTabContacts);

  const activeClient = useSelector(state => state.sessions.entities[state.sessions.activeSession]);
  const activeRecipient = useSelector(state => state.contacts.entities[activeClient?.callerId]);
 
  const activeGirlChatId = useSelector(state => state.girlChats.active);
  const activeClientChatId = useSelector(state => state.clientChats.active);

  const profilesContactInfo = useSelector(state => selectContactsByDivaId(state, profilesContactIds));
  const profilesContactList = profilesContactInfo.filter(item => typeof item === 'object');
  const notFoundedContactProfileIds = profilesContactInfo.filter(item => typeof item === 'number');

  // getting booking with active chat`s girl
  const divaProfileByActiveGirlChat = bufferContacts.find(contact => contact.id === activeGirlChatId);
  const divaProfileIdByActiveGirlChat = divaProfileByActiveGirlChat?.diva_default_id || divaProfileByActiveGirlChat?.diva_id;
  const bookingByCoincidenceWithActiveChat = useSelector(state => selectBookingForBufferChat(state, divaProfileIdByActiveGirlChat));
  
  // memoizing chat state for using chat components in buffer chat modal
  const memoizedGirlChatId = useMemo(() => activeGirlChatId, []);
  const memoizedActiveClientChat = useMemo(() => activeClientChatId, []);
  const memoizedActiveGirlContact = useSelector(state => state.contacts.entities[memoizedGirlChatId]);
  const memoizedActiveClientContact = useSelector(state => state.contacts.entities[memoizedActiveClientChat]);

  const dispatch = useDispatch();

  useEffect(() => {
    activeRecipient && dispatch(updateActiveContact(activeRecipient));
    !!profilesContactList.length && dispatch(updateActiveContact(profilesContactList[0]));

    if(!!notFoundedContactProfileIds.length) {
      API.getContactsByProfileDivaId(notFoundedContactProfileIds)
        .then(({ data }) => {
          const allProfilesAsContacts = [...profilesContactList, ...Object.values(data)];

          dispatch(setGirlsTabsInBuffer(allProfilesAsContacts));
        })
        .catch(console.error);
    } 
    else {
      !!profilesContactList.length && dispatch(setGirlsTabsInBuffer(profilesContactList));
    }

    return () => {
      memoizedActiveGirlContact 
        ? dispatch(updateActiveContact(memoizedActiveGirlContact))
        : removeContactTab(activeGirlChatId, 2);

      memoizedActiveClientContact 
        ? dispatch(updateActiveContact(memoizedActiveClientContact))
        : activeRecipient && dispatch(removeContactTab(activeRecipient.id, activeRecipient.type));

      dispatch(setGirlsTabsInBuffer([]));
    }
  }, []);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleOpenInSession = () => {
    handleCloseModal();
    dispatch(closeModal(MODAL_TYPES.profilesBuffer));
  };

  const handleOpenBookingDetails = () => {
    if(bookingByCoincidenceWithActiveChat) {
      dispatch(openModal(MODAL_TYPES.bookingEditForm, {
        booking: bookingByCoincidenceWithActiveChat,
        profileId: bookingByCoincidenceWithActiveChat.profile_id,
        callerId: bookingByCoincidenceWithActiveChat.callerId,
      }));
    }
  };

  return (
    <div className='buffer-chats-wrapper'>
      <header className='buffer-chats__header'>
        <div className='buffer-chats__btns-wrapper'>
          <button 
            onClick={handleOpenInSession} 
            className='buffer-chats__btn'
          >
            Open In Session
          </button>

          <button 
            onClick={handleCloseModal} 
            className='buffer-chats__btn'
          >
            Open In Buffer
          </button>

          <button 
            onClick={handleOpenBookingDetails}
            disabled={!bookingByCoincidenceWithActiveChat}
            className={classModifier('buffer-chats__btn', 'booking-details')}
          >
            Booking Details
            <ICONS.eye />
          </button>
        </div>

        <button
          className={classModifier('buffer-chats__btn', 'esc')}
          onClick={handleCloseModal}
        >
          <ICONS.close className="buffer-zone__esc-btn-icon"/> (Esc)
        </button>
      </header>

      <div className='buffer-chats'>
        <ClientChat isBufferChat />
        
        <div className='buffer-chats__chat-wrapper'>
          {!!bufferContacts?.length &&  <GirlChat isBufferChat />}
        </div>

        <BufferSearch />
      </div>
    </div>
  )
}

export default React.memo(BufferChats);
