import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';

import API from 'api/api';
import {
  getContactAvatar,
  classModifier
} from 'utils';
import { useToggle } from 'hooks';
import { isAllowed, selectContactById } from 'redux/selectors/selectors';
import { CHAT_SOURCES, PERMISSIONS } from 'config/constants';
import { updateActiveContact } from 'redux/ducks/clientChats';
import { BASE_DATE_CONFIG } from 'config/dates-сonfig';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import ICONS from 'assets/icons';
import MsgText from './MsgText';
// import InteractionMenu from '../InteractionMenu/InteractionMenu';
import TriStateSwitch from 'components/TriStateSwitch/TriStateSwitch';
import PinnedMsgAttachments from '../PinnedMsgs/PinnedMsgsAttachments';
import InteractionAttachments from '../../ChatAttachments/InteractionAttachments';
import DateTime from 'components/DateTime';
import { isNull } from 'lodash';

const IncomingGirlsChatMessage = ({
  interaction,
  activeRecipient,
  search,
  userRole,
  girl = {},
  setGlossaryState,
  updateActiveContact,
  ...props
}) => {
  const isGlossaryAllowed = useSelector((state) => isAllowed(state, PERMISSIONS.GLOSSARY));
  const caller = useSelector((state) => selectContactById(state, interaction.caller_id));

  const [glossaryItemInfo, setGlossaryItemInfo] = useState({
    glossaryItemId: null,
    glossaryType: null,
    glossaryMsg: null,
  });

  const isFromTelegram = interaction.type === 9;

  const [isMsgExpanded, toggleMsgExpanded] = useToggle(false);
  const [menuAndGlossaryDrop, toggleMenuAndGlossaryDrop] = useToggle(false);
  const [isOpenGlossary, toggleOpenGlossary] = useToggle(false);

  const handleMsgClick = (e) => {
    if (!e.target.className.indexOf) {
      return;
    }
    if (e.target.className.indexOf('interaction__ava') !== -1) {
      return updateActiveContact(girl);
    }
    else if (~e.target.className.indexOf('ii-')
      || ~e.target.className.indexOf('interaction__glossary')
      || ~e.target.className.indexOf('interaction__text-drop')
      || ~e.target.className.indexOf('lazy-load__image')
      || ~e.target.className.indexOf('video-thumbnail')) {
      return;
    }
    toggleMsgExpanded();
  }

  const addMessageToAvailableGlossary = (apiType, glossaryType) => {
    API.addMessageToAvailableGlossary(interaction.id, apiType)
      .then(({ data }) => {
        setGlossaryState(prev => ({
          glossaryMsg: [...prev.glossaryMsg, data.glossary?.message],
          interactionIds: [...prev.interactionIds, interaction.id]
        }));
        setGlossaryItemInfo(({
          glossaryItemId: data.glossary?.id,
          glossaryType,
          glossaryMsg: data.glossary?.message,
        }));
      })
      .catch(console.error);
  }

  const removeMessageFromAvailableGlossary = () => {
    API.removeMessageFromAvailableGlossary(glossaryItemInfo.glossaryItemId, interaction.id)
      .then(() => {
        setGlossaryState(prev => ({
          glossaryMsg: [...prev.glossaryMsg.filter((msg) => msg !== glossaryItemInfo.glossaryMsg)],
          interactionIds: [...prev.interactionIds.filter((id) => id !== interaction.id)],
        }));
        setGlossaryItemInfo({
          glossaryItemId: null,
          glossaryType: null,
          glossaryMsg: null,
        });
        toggleOpenGlossary(false);
      })
      .catch(console.error);
  };

  const glossaryItems = [
    {
      title: 'available',
      action: () => glossaryItemInfo.glossaryType === 'inAvailable'
        ? removeMessageFromAvailableGlossary()
        : addMessageToAvailableGlossary('in', 'inAvailable'),
      isDisabled: glossaryItemInfo.glossaryType === 'inOff' || glossaryItemInfo.glossaryType === 'finished',
    },
    {
      title: 'off-today',
      action: () => glossaryItemInfo.glossaryType === 'inOff'
        ? removeMessageFromAvailableGlossary()
        : addMessageToAvailableGlossary('out', 'inOff'),
      isDisabled: glossaryItemInfo.glossaryType === 'inAvailable' || glossaryItemInfo.glossaryType === 'finished',
    },
    {
      title: 'finished',
      action: () => glossaryItemInfo.glossaryType === 'finished'
        ? removeMessageFromAvailableGlossary()
        : addMessageToAvailableGlossary('finished', 'finished'),
      isDisabled: glossaryItemInfo.glossaryType === 'inAvailable' || glossaryItemInfo.glossaryType === 'inOff',
    },
    {
      title: 'started',
      action: () => glossaryItemInfo.glossaryType === 'started'
        ? removeMessageFromAvailableGlossary()
        : addMessageToAvailableGlossary('started', 'started'),
      isDisabled: glossaryItemInfo.glossaryType === 'inAvailable' || glossaryItemInfo.glossaryType === 'inOff',
    }
  ];

  const handleOpenNewTasksModal = () => (
    props.openModal(MODAL_TYPES.addNewTasksOrRemaindersModal, {
      item: { interaction, interaction_id: interaction.id },
      isWebmaster: true,
      isCreate: true,
      caller
    })
  )

  // const showInteractionMenu = () => {
  //   if (interaction.is_deleted) return null;

  //   return <InteractionMenu
  //     isOpen={menuAndGlossaryDrop}
  //     menuItems={items}
  //     closeMenu={toggleMenuAndGlossaryDrop} />
  // };

  const isVisibleGlossaryDropBtn =
    !glossaryItemInfo.glossaryItemId &&
    menuAndGlossaryDrop &&
    !interaction.is_deleted &&
    props.activeChatSource === CHAT_SOURCES.MSGS;

  return (
    <li
      className={classModifier('interaction', [
        isVisibleGlossaryDropBtn && 'open-menu',
        'girls'
      ])}
      onClick={handleMsgClick}
      onMouseEnter={() => toggleMenuAndGlossaryDrop(true)}
      onMouseLeave={() => {
        toggleMenuAndGlossaryDrop(false)
        toggleOpenGlossary(false)
      }}
    >
      <header className="interaction__header">
        <div className={classModifier('interaction__ava', girl.availability)}>
          <img
            src={getContactAvatar(girl)}
            className="interaction__ava-img"
            alt="ava"
          />

          {isFromTelegram &&
            <ICONS.telegram className="interaction__ava-telegram-icon" />
          }
        </div>

        <div className="interaction__header-info">
          <span className="interaction__name">
            {girl && (girl.short_name || girl.fn)}

            {/* {girl.exclusive &&
              <FontAwesomeIcon
                icon={faStar}
                title="exclusive"
                className="interaction__name--exclusive"
              />
            } */}

            <span className="interaction__rates">
              {girl && !!girl.rates && girl.rates}
            </span>
          </span>

          {!isVisibleGlossaryDropBtn ? (
            <div className="interaction__right-info">
              {!isNull(girl.availability) && (
                <TriStateSwitch
                  value={girl.availability ? girl.availability : 'waiting'}
                  onChange={(value) => API.changeGirlsStatus(value, girl.id)}
                />
              )}

              <DateTime
                className={classModifier('interaction__time', 'msg')}
                date={interaction.dateCreated}
                config={BASE_DATE_CONFIG}
              />

              <div className="interaction__w-wrapper">
                {interaction.for_web_master && <ICONS.w />}
              </div>
            </div>
          ) : (
            <div className='interaction__control-panel control-panel'>
              {!interaction.for_web_master && (
                <button
                  className='control-panel__chat-button'
                  onClick={handleOpenNewTasksModal}
                >
                  <ICONS.w className='control-panel__chat-button-icon' />
                </button>
              )}

              <button
                className='control-panel__chat-button'
                onClick={() => updateActiveContact(girl)}
              >
                <ICONS.comments className='control-panel__chat-button-icon' />
              </button>

              <TriStateSwitch
                value={girl.availability ? girl.availability : 'waiting'}
                onChange={(value) => API.changeGirlsStatus(value, girl.id)}
              />
            </div>
          )}
        </div>
      </header>

      <div
        className={classModifier('interaction__body-wrap', [
          interaction.is_deleted && 'deleted',
        ])}
      >
        <div className="interaction__text-wrap">
          {glossaryItemInfo.glossaryItemId &&
            <ICONS.undo
              className="interaction__glossary-undo"
              onClick={removeMessageFromAvailableGlossary}
            />
          }

          {(interaction.body || !interaction.attachments) &&
            <div className="interaction__text-wrap">
              <MsgText
                highlightedText={search}
                interaction={interaction}
                isMsgExpanded={isMsgExpanded}
                mode={glossaryItemInfo.glossaryType}
              />
            </div>
          }

          {interaction.attachments && (isMsgExpanded ? (
            <InteractionAttachments
              attachments={interaction.attachments}
              profileId={props.userId}
            />
          ) : (
            <PinnedMsgAttachments
              attachments={interaction.attachments}
              isMenuOpen={false}
            />
          ))
          }
        </div>

        {isVisibleGlossaryDropBtn && isGlossaryAllowed &&
          <ICONS.chevron
            className={classModifier('interaction__glossary-drop', [
              isOpenGlossary && 'open'
            ])}
            onClick={() => toggleOpenGlossary()}
          />
        }
      </div>

      {/* {showInteractionMenu()} */}

      {!glossaryItemInfo.glossaryItemId && isOpenGlossary &&
        <ul className="interaction__glossary">
          {glossaryItems.map(({ title, action, isDisabled }, idx) => {
            return (
              <li
                key={idx}
                onClick={() => (!isDisabled && action())}
                className={classModifier('interaction__glossary-item', [
                  isDisabled && 'disabled'
                ])}
              >
                <span className={classModifier('interaction__glossary-item-icon', title)}></span>
                Add to glossary as <span className="interaction__glossary-item-title">{title.split('-').join(' ')}</span>
              </li>
            )
          })}
        </ul>
      }
    </li>
  )
}

const mapStateToProps = (state, { interaction: { caller_id } }) => ({
  girl: selectContactById(state, caller_id),
  userRole: state.user.role,
  userId: state.user.id,
});

const mapDispatchToProps = ({
  updateActiveContact,
  openModal,
})

export default connect(mapStateToProps, mapDispatchToProps)(IncomingGirlsChatMessage);
