import { CONTACT_TYPES } from 'config/constants';
import { NOTIFICATION_TYPES } from '../redux/ducks/notifications';

export const getNotificationProps = (item, field, callerType) => {
  switch (field) {
    case 'chatId': {
      return item.data?.chatId;
    }
    case 'callerType': {
      return item.data?.caller_type;
    }
    case 'chatTitle': {
      return item.data?.chat?.chatTitle;
    }
    case 'clientId': {
      if (item.is_reminder) {
        return item.caller_id;
      }

      if (item.data.contactIds) {
        return item.data.contactIds;
      }

      if (item.data.mail) {
        return item.data.mail.caller.id;
      }

      return item.data.caller_id || item.data.callerId;
    }
    case 'girlId': {
      if (item.is_reminder) {
        return item.girlId;
      }

      return item.data.girlId;
    }
    case 'callerId': {
      if (callerType === CONTACT_TYPES.CLIENT) {
        return [getNotificationProps(item, 'clientId')].flat();
      }

      if (callerType === CONTACT_TYPES.GIRL) {
        return [getNotificationProps(item, 'girlId')].flat();
      }

      return [getNotificationProps(item, 'clientId'), getNotificationProps(item, 'girlId')].flat().filter(Boolean);
    }
    case 'mailConversationId': {
      return item.data?.mail?.conversationId;
    }
    case 'message': {
      if (item.is_reminder) {
        return item.interaction.message || item.body;
      }

      return item.body || item.data.message || item.data.callersInteractions?.body;
    }
    case 'messageId': {
      if (item.is_reminder) {
        return item.message_id;
      }

      if (item.data.callersInteractions) {
        return item.data.callersInteractions.id;
      }

      return item.data?.message_id || item.data?.id;
    }
    case 'messageType': {
      if (item.is_reminder) {
        return 'reminder';
      }

      return item.data.type || item.type;
    }
    case 'operatorId': {
      if (item.type === NOTIFICATION_TYPES.sessionChanged) {
        return item.data.change_maker_user_id;
      }

      return item.data?.user_id || item.data?.userId;
    }
    case 'sessionId': {
      if (item.is_reminder) {
        return item.session_id;
      }

      return item.data.session_id;
    }
    case 'isHighlighted': {
      return item.isHighlighted;
    }
  }
}
