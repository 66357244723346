import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import {
  updateContactsList,
  toggleContactPin,
  toggleContactMark,
  CHAT_CONTACTS_LIMIT,
  toggleContactBlock,
  removeContactFilter,
  getMarkedContacts,
  getContactsWithUnread,
  getRelatedContacts,
  getActiveChats,
  getOnlyHotels,
  getOnlyAgents,
  getNotTextedForLong,
  ALL_FILTER,
  UNREAD_FILTER,
  MARKED_FILTER,
  RELATED_FILTER,
  ACTIVE_CHATS,
  toggleSearchSource,
  toggleMessageSource,
  toggleMainFilter,
} from 'redux/ducks/contacts';
import { CONTACT_TABS, CONTACT_TYPES } from 'config/constants';
import API from 'api/api';
import { usePrevious, useDidUpdate, useDidMount, useCancelToken } from 'hooks';
import { selectClientsList } from 'redux/selectors/selectors';
import { openModal, MODAL_TYPES, closeAllModals } from 'redux/ducks/activeWindows';
import { updateSearchContactsByType, stopContactsSearchByType, searchContactsByType } from 'redux/ducks/contacts';
import LS from 'utils/localStorageAPI';
import ICONS from 'assets/icons';
import { createContactChat } from 'redux/ducks/clientChats';

import './SidebarClientsChatList.scss';
import SidebarContactsFilters from '../components/SidebarContactsFilters/SidebarContactsFilters';
import SidebarContactsList from '../components/SidebarContactsList/SidebarContactsList';
import ContactItem from '../components/SidebarContactsList/ContactItem';
import SidebarHeader from '../components/SidebarHeader/SidebarHeader';
import SearchListContactItem
  from '../../../components/SearchList/components/SearchListContactItem/SearchListContactItem';

const SEARCH_LIST_SIZE = 20;

const SidebarClientsChatList = ({ openModal, clientUnreadCount, clientsSearchSource, closeAllModals, ...props }) => {
  const prevClientsSearchSource = usePrevious(clientsSearchSource);

  // To cancel axios http request
  const { newCancelToken, cancelPrevRequest } = useCancelToken();

  const contactFilters = useMemo(() => {
    const mainFilters = [
      { name: ALL_FILTER, action: () => props.removeContactFilter('clients') },
      { name: MARKED_FILTER, action: () => props.getMarkedContacts(1) },
      { name: UNREAD_FILTER, action: () => props.getContactsWithUnread(1) },
      // { name: RELATED_FILTER, action: () => props.getRelatedContacts(1) },
      { name: ACTIVE_CHATS, action: () => props.getActiveChats(1) },
    ];

    const operatorsFilters = [
      { name: ALL_FILTER, action: () => props.removeContactFilter('clients') },
      { name: UNREAD_FILTER, action: () => props.getContactsWithUnread(1) },
      { name: ACTIVE_CHATS, action: () => props.getActiveChats(1) },
    ]

    if (['my', 'all'].includes(props.clientsMainActiveFilter)) {
      return mainFilters;
    } else {
      return operatorsFilters;
    }
  }, [props.clientsMainActiveFilter]);

  useDidMount(() => {
    if (props.searchClients) {
      props.searchContactsByType(
        props.searchClients,
        CONTACT_TABS.CLIENTS,
        clientsSearchSource,
        newCancelToken(),
      );
    }

    if (props.clientsActiveFilter !== ALL_FILTER) {
      getClientListRequest(true)(CONTACT_TYPES.CLIENT, 0, true);
    }
  });

  useDidUpdate(() => {
    // if (props.searchClients) return;

    if (props.clientsActiveFilter !== ALL_FILTER) {
      const isClientFilterExistInAvailableFilters
        = contactFilters.find((filter) => filter.name === props.clientsActiveFilter);

      if (isClientFilterExistInAvailableFilters) {
        if (props.searchClients) {
          getClientListRequest(true)(CONTACT_TYPES.CLIENT, 0, true);
        } else {
          getClientListRequest()(CONTACT_TYPES.CLIENT);
        }
      } else {
        props.removeContactFilter('clients');
      }
    }
  }, [props.clientsMainActiveFilter])

  useDidUpdate(() => {
    startSearch(props.searchClients);
  }, [clientsSearchSource]);

  useDidUpdate(() => {
    LS.setItem('clientsSearch', props.searchClients)
  }, [props.searchClients]);

  useDidUpdate(() => {
    LS.setItem('clientsActiveFilter', props.clientsActiveFilter);
  }, [props.clientsActiveFilter]);

  const getClientListRequest = (ignoreSearch = false) => {
    if (props.searchClients && !ignoreSearch) {
      return props.updateSearchContactsByType;
    }
    else if (props.clientsActiveFilter === MARKED_FILTER) {
      return props.getMarkedContacts;
    }
    else if (props.clientsActiveFilter === UNREAD_FILTER) {
      return props.getContactsWithUnread;
    }
    else if (props.clientsActiveFilter === RELATED_FILTER) {
      return props.getRelatedContacts;
    }
    else if (props.clientsActiveFilter === ACTIVE_CHATS) {
      return props.getActiveChats;
    }
    return props.updateContactsList;
  };

  const blockContact = (contact) => {
    // if (contact.type === 9) return;             // Saved msg

    if (contact.blocked) {
      return props.toggleContactBlock(contact);
    }

    return openModal(MODAL_TYPES.blockForm, { contact: contact.id });
  };

  const fetchSearchContacts = ({ query, offset, cancelToken, ignoredId }) => {
    return API.searchContacts(null, query, offset, SEARCH_LIST_SIZE, cancelToken)
      .then(({ data }) => ({
        newItems: data,
        newHasMore: data.length === SEARCH_LIST_SIZE,
      }))
      .catch(console.error);
  };

  const generateContextItems = (contact) => {
    const items =
      [
        {
          text: `${contact.pinned ? 'Unp' : 'P'}in contact`,
          action: () => props.toggleContactPin(contact),
        },
        {
          text: `${contact.marked ? 'Unm' : 'M'}ark contact`,
          action: () => props.toggleContactMark(contact),
        },
        {
          text: `${contact.blocked ? 'Un' : 'B'}lock contact`,
          action: () => blockContact(contact),
        },
        {
          text: 'Merge contact to ...',
          action: () => {
            openModal(MODAL_TYPES.searchList, {
              ignoredId: contact.id,
              fetchData: fetchSearchContacts,
              itemComponent: SearchListContactItem,
              onChoose: (selectedContact) => {
                openModal(
                  MODAL_TYPES.mergeContacts,
                  {
                    donorContact: contact,
                    acceptorContact: selectedContact,
                    onSubmit: () => {
                      closeAllModals();
                    },
                  },
                );
              },
            });
          },
        },
        {
          text: `${contact.is_active_chat ? 'Remove from' : 'Add to'} active chats`,
          action: () => API.toggleContactActiveChat(contact),
        },
      ]

    items.push();

    return items;
  };

  const stopSearch = useCallback(() => {
    cancelPrevRequest();
    props.stopContactsSearchByType('clients');
  }, []);

  const startSearch = (query) => {
    if (query !== props.searchClients || prevClientsSearchSource !== clientsSearchSource) {
      cancelPrevRequest();

      return props.searchContactsByType(query, 'clients', clientsSearchSource, newCancelToken());
    }
  };
  
  const extraButton = (
    <button
      className='menu'
      onClick={props.createContactChat}
    >
      <ICONS.plusMessage />
    </button>
  )

  const getDividedContactId = () => {
    if (
      props.clientsMainActiveFilter === 'my' &&
      props.clientsActiveFilter === ACTIVE_CHATS &&
      !props.searchClients
    ) {
      const clientsIds = props.clients;

      const idIndex = clientsIds.findLastIndex((id) => {
        const contact = props.contactsEntities[id];

        return !contact?.relatedUserId;
      });

      if (idIndex === -1 && idIndex === 0) {
        return null;
      } else {
        return clientsIds[idIndex];
      }
    }

    return null;
  };

  const dividedContactId = getDividedContactId();
  const pending = props.clientsActiveFilter === ALL_FILTER
    ? props.clientsPending
    : props.clientsFilteredPending;
  
  return (
    <div className="sidebar-sections">
      <SidebarHeader
        className="sidebar-sections__title title"
        type='Clients'
        extraButton={extraButton}
      />

      <div className="sidebar-sections__filters">
        <SidebarContactsFilters
          initialQuery={props.searchClients}
          activeTab='clients'
          startSearch={startSearch}
          stopSearch={stopSearch}
          filters={contactFilters}
          activeSelectFilter={props.activeFilter}
          defaultFilter={ALL_FILTER}
          contactType='clients'
          searchSource={clientsSearchSource}
          toggleSearchSource={props.toggleSearchSource}
          toggleMessageSource={props.toggleMessageSource}
          toggleMainFilter={props.toggleMainFilter}
          filtersCounters={props.filtersCounters}
          clientsMainActiveFilter={props.clientsMainActiveFilter}
        />
      </div>

      <div className="sidebar-sections__list">
        <SidebarContactsList
          list={props.clients}
          pending={pending}
          search={props.searchClients}
          filter={props.clientsActiveFilter}
          mainFilter={props.clientsMainActiveFilter}
          updateList={getClientListRequest()}
          limit={CHAT_CONTACTS_LIMIT}
          type={1}

          dividedContactId={dividedContactId}
          listItem={ContactItem}
          generateContextItems={generateContextItems}

          searchSource={clientsSearchSource}
        />
      </div>
    </div>
  );
};


const mapStateToProps = state => ({
  clients: selectClientsList(state, 1),
  clientsPending: state.contacts.clients.pending,
  clientsFilteredPending: state.contacts.clients.filteredPending,
  clientsActiveFilter: state.contacts.clients.activeFilter,
  clientsMainActiveFilter: state.contacts.clients.mainActiveFilter,
  searchClients: state.contacts.clients.search,
  clientsTotalCount: state.contacts.clients.totalCount,
  clientUnreadCount: state.contacts.clients.unreadCount,
  activeFilter: state.contacts.clients.activeFilter,
  clientsSearchSource: state.contacts.clients.searchSource,
  filtersCounters: state.contacts.clients.filtersCounters,
  contactsEntities: state.contacts.entities,
});

const mapDispatchToProps = {
  updateContactsList,
  updateSearchContactsByType,
  toggleContactPin,
  toggleContactMark,
  getContactsWithUnread,
  getMarkedContacts,
  getRelatedContacts,
  getActiveChats,
  toggleContactBlock,
  stopContactsSearchByType,
  searchContactsByType,
  removeContactFilter,
  getOnlyHotels,
  getOnlyAgents,
  getNotTextedForLong,
  openModal,
  closeAllModals,
  toggleSearchSource,
  toggleMessageSource,
  toggleMainFilter,
  createContactChat
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarClientsChatList);
