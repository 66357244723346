import { createContext, useContext, useRef, useState } from 'react';
import classNames from 'classnames';

import { classModifier } from 'utils';
import modifyValues from 'utils/modifyValues';

import './NotificationBlock.scss';
import ICONS from 'assets/icons';
import Spinner from 'components/UI/Spinner/Spinner';
import TypingIndicator from 'components/UI/TypingIndicator/TypingIndicator';
import DateTime from 'components/DateTime';
import { NOTIFICATION_DATE_CONFIG } from 'config/dates-сonfig';


const NotificationContext = createContext(null);


const SpinnerPlug = () => (
  <div className='notification__spinner-wrapper'>
    <Spinner className="notification__spinner" spinnerSize={50} />
  </div>
)


const Body = (props) => {
  const contextProps = useContext(NotificationContext);
  const realProps = { ...props, ...contextProps };
  const { children, readAction, item, type } = realProps;

  const entitiesJSX = modifyValues(realProps.entities, (Component, name) => (
    <Component className={`notification__${name}`} key={name} notification={item} />
  ));

	return (
    <article className="notification__body">
      {!realProps.isNotAvatarRendered && (
        <div className="notification__avatar-wrapper">
          {realProps.getRenderedAvatar?.(entitiesJSX?.callers) || entitiesJSX?.defaultAvatar}
        </div>
      )}

      <p className='notification__paragraph'>
        {children instanceof Function
          ? children({ ...entitiesJSX, notification: item, readAction })
          : (children || type)}
      </p>
    </article>
  )
}


const Attachments = ({ children, pending }) => {
  const { pending: generalPending } = useContext(NotificationContext);

  return (
    <div className='notification__attachments-wrapper'>
      {pending && !generalPending && <SpinnerPlug />}

      <div className={classModifier('notification__attachments')}>
        {children}
      </div>
    </div>
  )
}


const Footer = (props) => {
  const {
    item,
    readAction,
    pending,
    iconType = props.iconType
  } = useContext(NotificationContext);
  const [readPending, setReadPending] = useState(false);
  const readBtnRef = useRef(null);
  const Icon = ICONS[iconType || "bellFill"];

  const afterClickAction = (event) => {
    if (readBtnRef.current === event.target) return;

    const closeEvent = new CustomEvent('notificationButtonClick');
    dispatchEvent(closeEvent);
  }

  const handleRead = () => {
    setReadPending(true);

    readAction()
      .then(() => setReadPending(false));
  }

  return (
    <footer
      className={classModifier("notification__footer", pending && "pending")}
      onClick={afterClickAction}
    >
      <div className="notification__footer-block">
        <Icon className="notification__icon" />

        <DateTime
          className="notification__date"
          date={item.date_created}
          config={NOTIFICATION_DATE_CONFIG}
        />
      </div>

			<div className="notification__btns-wrapper">
				{props.children}

        <button
          onClick={handleRead}
          className="notification__read-btn"
          ref={readBtnRef}
        >
          {readPending ? <TypingIndicator /> : 'Read'}
        </button>
      </div>
    </footer>
  )
}

export const NotificationBlock = ({
  className,
  children,
  isDeprecated,
  isHighlighted,
  deprecatedLabel,
  ...restProps
}) => {
  const classes = classNames(
    className,
    classModifier("notification", [
      isDeprecated && "deprecated",
      restProps.item.isHighlighted && "highlighted",
    ])
  )

  const DefaultContent = () => (
    <>
      <Body>{restProps.getText}</Body>
      <Footer />
    </>
  )

  return (
    <NotificationContext.Provider value={{ ...restProps }}>
      <div className={classes} title={deprecatedLabel}>
        {restProps.pending && <SpinnerPlug />}
        {children || <DefaultContent />}
      </div>
    </NotificationContext.Provider>
  );
}

NotificationBlock.Body = Body;
NotificationBlock.Attachments = Attachments;
NotificationBlock.Footer = Footer;

