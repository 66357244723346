import { useDispatch, useSelector } from 'react-redux';

import API from 'api/api';
import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import {
  selectDefaultSession,
  selectPropertyOfActiveSession,
  selectCanIEditSession,
} from 'redux/selectors/selectors';
import { BUTTON_THEMES } from 'config/constants';
import { createSession, updateActiveSession, removeBufferedId, removeVisitedSession, deleteGirlsIdsThatLinksIsSendInChat, closeSession } from 'redux/ducks/sessions';
import { closeModal, MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import './SalesHeader.scss';
import GallerySearch from './components/GallerySearch/GallerySearch';
import SearchListContactItem from 'components/SearchList/components/SearchListContactItem/SearchListContactItem';
import ClientCard from 'components/ClientCard/ClientCard';
import Button from 'components/Button/Button';

const LIST_SIZE = 20;

const SalesHeader = ({ isNotFull, aSessionClientId, activeSessionId }) => {
  const dispatch = useDispatch();

  const activeSession = useSelector(state => state.sessions.activeSession);
  const activeClient = useSelector(state => state.contacts.entities[aSessionClientId]);
  const defaultSession = useSelector(selectDefaultSession);
  const canIEditSession = useSelector(state => selectCanIEditSession(state));
  const bookedIds = useSelector(state => selectPropertyOfActiveSession(state, 'bookedIds'));
  const bufferedIds = useSelector(state => selectPropertyOfActiveSession(state, 'bufferedIds'));
  const isHistory = useSelector(state => state.sessions.visitedHistoryIds.includes(activeSessionId));
  const arrayGirlsIdsThatLinksIsSend = useSelector(state => state.sessions.entities[activeSession]?.messageSendGirlsIds);

  const fetchSearchContacts = ({ query, offset, cancelToken }) => {
    return API.searchContacts(1, query, offset, LIST_SIZE, cancelToken)
      .then(({ data }) => ({
        newItems: data,
        newHasMore: data.length === LIST_SIZE,
      }))
      .catch(console.error);
  };

  const onAddClient = () => {
    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: fetchSearchContacts,
      itemComponent: SearchListContactItem,
      onChoose: (selectedContact) => {
        dispatch(closeModal(MODAL_TYPES.SearchList));

        if (activeSession === 0) {
          dispatch(createSession(
            selectedContact.id,
            defaultSession.bufferedIds,
            defaultSession.comparedIds,
            defaultSession.activeFilters,
            defaultSession.additionalFilters,
            defaultSession.recentlyViewedIds,
          ))
        }
        else {
          dispatch(createSession(selectedContact.id));
        }
      },
    }));
  };

  const handleClearBuffer = () => {
    if (window.confirm('Clear buffer ?')) {
      dispatch(removeBufferedId(bufferedIds, activeSession, canIEditSession));
    }
  };

  const handleOpenBuffer = () => {
    bufferedIds.length && dispatch(openModal(MODAL_TYPES.profilesBuffer, { sessionId: activeSession }));
  };

  const handleProfilesBookedOpen = () => {
    bookedIds.length && dispatch(openModal(MODAL_TYPES.profilesBooked, { sessionId: activeSession }));
  };

  const onCloseSession = () => {
    const question = `Close session with client ${activeClient.fn}?`;

    if (isHistory) {
      return dispatch(removeVisitedSession(activeSessionId));
    }

    if (window.confirm(question)) {
      dispatch(closeSession(activeSessionId))

      if (arrayGirlsIdsThatLinksIsSend?.length) {
        dispatch(deleteGirlsIdsThatLinksIsSendInChat(activeSessionId));
      }
    }
  }

  const onEditContact = () => {
    dispatch(openModal(MODAL_TYPES.contactCard, { contact: activeClient.id }));
  }

  return (
    <header className="sales-header">
      <div className="sales-header__left-wrap">
        <ICONS.puzzle className="sales-header__sales-page-btn" />

        <Button
          className="sales-header__default-session-btn"
          theme={BUTTON_THEMES.primary}
          onClick={() => {
            API.switchSalesSession(0);
            dispatch(updateActiveSession(0));
          }}
          icon={<ICONS.plusCircle />}
        >
          Default<br />Session
        </Button>

        {activeSessionId === 0 ? (
          <Button
            className="sales-header__add-client-btn"
            theme={BUTTON_THEMES.primary}
            onClick={onAddClient}
            icon={<ICONS.adrBookInvert />}
          >
            Add Client
          </Button>
        ) : (
          <ClientCard
            className="sales-header__client-card"
            activeClient={activeClient}
            // onClose={activeSession.active && onCloseSession}
            onEdit={onEditContact}
          />
        )}
      </div>

      <div className="sales-header__right-wrap">
        {/* Most likely: to replace with new component */}
        <div className="sales-header__buffer-button-wrap">
          <Button
            className={classModifier('sales-header__secondary-button', [
              !bufferedIds.length && 'disabled',
            ])}
            theme={BUTTON_THEMES.secondary}
            onClick={handleOpenBuffer}
            text={`Buffer ( ${bufferedIds.length} )`}
            icon={<ICONS.buffer />}
          >
            Buffer ( {bufferedIds.length} )
          </Button>

          {!!bufferedIds.length && (
            <button
              className="sales-header__clear-buffer-button"
              onClick={handleClearBuffer}
            >
              <ICONS.close />
            </button>
          )}
        </div>

        {/* Most likely: to replace with new component */}
        <Button
          className={classModifier('sales-header__secondary-button', [
            !bookedIds.length && 'disabled',
          ])}
          theme={BUTTON_THEMES.secondary}
          onClick={handleProfilesBookedOpen}
          text={`Booked (${bookedIds.length})`}
          icon={<ICONS.booking />}
        >
          Booked ({bookedIds.length})
        </Button>

        <div className="sales-header__search-wrap">
          {!isNotFull && <GallerySearch />}
        </div>
      </div>
    </header>
  );
}

export default SalesHeader;
