import React, { createContext, useContext } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import API from 'api/api';
import { CONTACTS_LIMIT } from 'config/constants';
import { getContactAvatar } from 'utils';
import fetchDivaGirls from 'utils/fetchDivaGirls';
import fetchSearchClientsContacts from 'utils/fetchSearchClientsContacts';
import { closeModal, MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { BOOKING_SELECT_FILTERS_NAMES } from 'components/FilteredBookings/config/constants';
import { selectOperatorById } from 'redux/selectors/selectors';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import BookingSelectFilter from 'components/SelectFilter/SelectFilter';
import QuickBookingGirlsListItem from 'containers/Chat/components/QuickBookingGirlsListItem/QuickBookingGirlsListItem';
import SearchListContactItem from 'components/SearchList/components/SearchListContactItem/SearchListContactItem';


const OperatorOptionIcon = ({ item, className }) => {
  const operator = useSelector((state) => selectOperatorById(state, item.id));

  return <LazyLoadImage src={getContactAvatar(operator)} className={className} />
}

const ClientOptionIcon = ({ item, className }) => {
  return <LazyLoadImage src={getContactAvatar(item)} className={className} />
}

const EscortOptionIcon = ({ item, className }) => {
  return <LazyLoadImage src={getContactAvatar({ ...item, type: 2 })} className={className} />
}


const OptionIcons = {
  [BOOKING_SELECT_FILTERS_NAMES.girl]: EscortOptionIcon,
  [BOOKING_SELECT_FILTERS_NAMES.client]: ClientOptionIcon,
  [BOOKING_SELECT_FILTERS_NAMES.operator]: OperatorOptionIcon,
  [BOOKING_SELECT_FILTERS_NAMES.agent]: null,
}

const searchListPropsByFilterName = {
  [BOOKING_SELECT_FILTERS_NAMES.girl]: [fetchDivaGirls, QuickBookingGirlsListItem, 'diva_default_id'],
  [BOOKING_SELECT_FILTERS_NAMES.client]: [fetchSearchClientsContacts, SearchListContactItem, null]
}

const bookingSelectRequests = {
  [BOOKING_SELECT_FILTERS_NAMES.girl]: API.getEscortOptionsForBookingsFilter,
  [BOOKING_SELECT_FILTERS_NAMES.client]: API.getCallersOptionsForBookingsFilter,
  [BOOKING_SELECT_FILTERS_NAMES.operator]: API.getOperatorsOptionsForBookingsFilter,
  [BOOKING_SELECT_FILTERS_NAMES.agent]: API.getAgentsFilters,
}


const onAddValue = (fetchData, itemComponent, pathToId, dispatch) => (onSelect) => {
  dispatch(openModal(MODAL_TYPES.searchList, {
    fetchData,
    itemComponent,
    limit: CONTACTS_LIMIT,
    onChoose: (selectedContact) => {
      onSelect(selectedContact, pathToId);
      dispatch(closeModal(MODAL_TYPES.SearchList))
    }
  }))
}

const BookingSelectFiltersContext = createContext(null);

export const BookingSelectFilters = ({ getterProps, className, classModifier, children }) => (
  <BookingSelectFiltersContext.Provider value={{ classModifier, getterProps }}>
    <div className={classNames(className, "booking-select-filters")}>
      {children}
    </div>
  </BookingSelectFiltersContext.Provider>
)

Object.values(BOOKING_SELECT_FILTERS_NAMES).forEach((filterName) => {
  const Component = ({ getterProps, className }) => {
    const contextProps = useContext(BookingSelectFiltersContext);
    const { newRequest, ...restProps } = (contextProps?.getterProps || getterProps)({ name: filterName })
    const searchListProps = searchListPropsByFilterName[filterName];
    const actualClassnames = className
      ? classNames('booking-select-filters__item', className)
      : classNames('booking-select-filters__item', `${contextProps.classModifier}__item`)

    const dispatch = useDispatch();

    return (
      <BookingSelectFilter
        className={actualClassnames}
        OptionIcon={OptionIcons[filterName]}
        request={newRequest(bookingSelectRequests)}
        {...(searchListProps ? { innerHandleClick: onAddValue(...searchListProps, dispatch) } : {})}
        {...restProps}
      />
    )
  }

  BookingSelectFilters[filterName] = Component;
})
