import React from 'react';

import './OpenCommModule.scss';

const OpenCommModule = ({ title = 'Please open Comm module.' }) => {
  return (
    <div className="comm-module-modal">
      <h2 className="comm-module-modal__title">{title}</h2>
    </div>
  );
}

export default OpenCommModule;
