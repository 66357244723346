export const CENTER_COORDINATES = { lat: 51.50208118987632, lng: -0.16862733974063682 };
export const DEFAULT_ZOOM = 14;
export const CLIENT_INITIAL_OBJECT = { longitude: null, latitude: null, radius: { value: 0 } };
export const RADIUS_OPTIONS = [
  { value: 0.5, label: '0.5 miles' },
  { value: 1, label: '1 miles' },
  { value: 1.5, label: '1.5 miles' },
  { value: 2, label: '2 miles' },
]

export const MAP_DEFAULT_OPTIONS = {
  fullscreenControl: true,
  streetViewControl: true,
  mapTypeControl: true,
  scaleControl: true,
};

export const LIBRARIES = ["places"];

export const MAP_MODES = {
  SESSION_ESCORTS: 'session-escorts',
  ALL_ESCORTS: 'all-escorts',
  NAVIGATION: 'navigation',
  SELECTION: 'selection',
}
