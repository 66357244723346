import React, { Fragment, useState } from 'react';

import { getDateByTimezoneOffset } from 'utils';

import IncomingMessage from './interactions/IncomingMessage';
import OutgoingMessage from './interactions/OutgoingMessage';
import IncomingGirlsChatMessage from './interactions/IncomingGirlsChatMessage';
import RemindMsg from './interactions/RemindMsg/RemindMsg';
import SystemMsg from './interactions/SystemMsg/SystemMsg';
import DateIndicator from './DateIndicator/DateIndicator';
import UnreadIndicator from './UnreadIndicator';
import Spinner from 'components/UI/Spinner/Spinner';
import WebmasterMessage from './interactions/WebmasterMessage';
import IncomingGroupMessage from './interactions/IncomingGroupMessage';
import OutgoingGroupMessage from './interactions/OutgoingGroupMessage';
import Button from '../../../../components/Button/Button';
import { BUTTON_THEMES } from '../../../../config/constants';
import { updateActiveSession } from '../../../../redux/ducks/sessions';
import ICONS from '../../../../assets/icons';

const NoSearchResult = ({title = "", buttonText, onClickCb}) => (
  <>
    <span className="timeline__no-results">
      No results found. <br />
      {title}
    </span>
    
    {buttonText 
      && (
        <button
        onClick={onClickCb}
        className="timeline__btn--global-search"
        >
          {buttonText}
        </button>
      )
    }
  </>
)

const InteractionsList = (props) => {
  const {
    search,
    activeRecipient,
    timeline,
    unreadRef,
    profileId,
    contextMsgId,
    contextMsgRef,
    getMessageContext,
    shareMsg,
    activeGroup,
  } = props;

  let firstUnread = false;

  const [glossaryState,setGlossaryState] = useState({glossaryMsg: [],interactionIds: [] });

  const renderInteractions = (interaction, unreadRef) => {
    if (!firstUnread && interaction.hasOwnProperty('isRead') && unreadRef && !interaction.isRead) {
      firstUnread = interaction.id;
    }

    if (activeRecipient.id === 'webmaster') {
      return <WebmasterMessage
        key={interaction.id}
        interaction={interaction}
        activeRecipient={activeRecipient}
      />
    }
    
    if (['girls', 'finished', 'started'].includes(activeRecipient.id)) {
      if (
        glossaryState &&
        !glossaryState.interactionIds.includes(interaction.id) &&
        glossaryState.glossaryMsg.includes(interaction.body)
      ) {
        return null;
      }
      return <IncomingGirlsChatMessage
        key={interaction.id}
        interaction={interaction}
        activeRecipient={activeRecipient}
        updateActiveContact={props.updateActiveContact}
        profileId={profileId}
        userTimezone={props.userTimezone}
        userHour12={props.userHour12}
        setGlossaryState={setGlossaryState}
        activeChatSource={props.activeChatSource}
      />
    }

    if (activeGroup) {
      if (interaction.out) {
        return <OutgoingGroupMessage
          interaction={interaction}
          replyMsg={props.replyMsg}
          activeGroup={activeGroup}
        />
      } else {
        return <IncomingGroupMessage
          interaction={interaction}
          replyMsg={props.replyMsg}
          activeGroup={activeGroup}
          activeRecipientId={activeRecipient.id}
        />
      }
    }

    switch (interaction.type) {
      case 1:
      case 2:
      case 15:
      case 16:
        return (
          <div key={interaction.id} className="timeline__system-msg">
            <SystemMsg
              interaction={interaction}
              userTimezone={props.userTimezone}
              userHour12={props.userHour12}
            />
          </div>
        );
      case 3:
      case 10:
      case 14:
      case 19:
      case 21:
      case 23:
        return (
          <OutgoingMessage
            editMsg={props.editMsg}
            interaction={interaction}
            activeRecipient={activeRecipient}
            search={search}
            profileId={profileId}
            key={interaction.id}
            contextMsgId={contextMsgId}
            contextMsgRef={contextMsgRef}
            shareMsg={shareMsg}
            replyMsg={props.replyMsg}
            isGlobalSearch={props.isGlobalSearch}
            getMessageContext={getMessageContext}
            userTimezone={props.userTimezone}
            userHour12={props.userHour12}
            addNewArrayGirlsToState={props.addNewArrayGirlsToState}
            updateActiveContact={props.updateActiveContact}
          />
        );
      case 4:
      case 9:
      case 13:
      case 18:
      case 20:
      case 22:
        return (
          <Fragment key={interaction.id}>
            {interaction.id === firstUnread && <UnreadIndicator ref={unreadRef} />}

            <IncomingMessage
              interaction={interaction}
              isWebmasterMsg={!!interaction.webMasterTask}
              search={search}
              activeRecipient={activeRecipient}
              profileId={profileId}
              contextMsgId={contextMsgId}
              contextMsgRef={contextMsgRef}
              shareMsg={shareMsg}
              replyMsg={props.replyMsg}
              addNewArrayGirlsToState={props.addNewArrayGirlsToState}
              isGlobalSearch={props.isGlobalSearch}
              updateActiveContact={props.updateActiveContact}
              getMessageContext={getMessageContext}
              userTimezone={props.userTimezone}
              userHour12={props.userHour12}
            />
          </Fragment>
        );
      case 7:
        return (
          <Fragment key={interaction.id}>
            {/* {interaction.id === firstUnread && <UnreadIndicator ref={unreadRef} />} */}

            <RemindMsg
              interactionId={interaction.id}
              getMessageContext={getMessageContext}
              interaction={interaction.interaction}
              removeMessageReminder={props.removeMessageReminder}
              profileId={profileId}
              activeRecipient={activeRecipient}
              isMainTimelineOpen={props.isMainTimelineOpen}
              remindAt={interaction.remind_at}
            >
              {/* {renderInteractions(interaction.interaction)} */}
            </RemindMsg>
          </Fragment>
        );
      case 8:
        return (
          <div key={interaction.id} className="timeline__system-msg">
            <SystemMsg
              interaction={interaction}
              userTimezone={props.userTimezone}
              userHour12={props.userHour12}
              activeRecipient={activeRecipient}
            />
          </div>
        );
      case 11:
        return (
          <OutgoingMessage
            interaction={interaction}
            key={interaction.id}
            userTimezone={props.userTimezone}
            userHour12={props.userHour12}
            editMsg={props.editMsg}
            openModal={props.openModal}
            addNewArrayGirlsToState={props.addNewArrayGirlsToState}
            profileId={profileId}
            updateActiveContact={props.updateActiveContact}
          />
        );
      case 12:
        return (
          <div key={interaction.id} className="timeline__system-msg">
            <SystemMsg
              interaction={interaction}
              userTimezone={props.userTimezone}
              userHour12={props.userHour12}
              activeRecipient={activeRecipient}
            />
          </div>
        );
      default:
        return (
          <div key={interaction.id}>Unknown interaction</div>
        );
    }
  }

  if (props.timelinePending) {
    return (
      <div className="timeline__load-wrap">
        <Spinner spinnerSize={30} />
      </div>
    )
  }
  else if (!timeline.length) {
    return (
      <div className="timeline__load-wrap">
        {!props.publicChat
        && search
        && props.startGlobalMsgSearch
          ? (
            props.isGlobalSearch
              ? props.isAuxiliaryArchiveDisplayed
                ? (
                <NoSearchResult />
                ) 
                : (
                <NoSearchResult 
                  title='Try global history search instead'
                  buttonText='Search All History Chats' 
                  onClickCb={() => props.updateGlobalContactMessageSearch(activeRecipient, null, 'up', true)}
                />
                ) 
              : (
                  <NoSearchResult 
                    title='Try global search instead'
                    buttonText='Search All Chats' 
                    onClickCb={() => props.startGlobalMsgSearch(search, activeRecipient)}
                  />
              ) 
        ):(
          <>
            <span className="timeline__no-results">
            No messages
            </span>

            {((props.type === 1 || props.type === 2) && (
              (props.auxiliaryHasArchive && props.search?.length && !props.isAuxiliaryArchiveDisplayed) ||
              (props.auxiliaryHasArchive && props.activeChatSource === 'media' && !props.isAuxiliaryArchiveDisplayed) ||
              (props.hasArchive && props.activeChatSource === 'msgs' && !props.isArchiveDisplayed && !props.search?.length)
              ) && (
              <button
                className="timeline__btn--archive timeline__btn--archive-search"
                onClick={() => {
                  props.updateContactTimeline(activeRecipient, null, 'up', true);
                }}
              >
                View Archive
              </button>
            ))}
          </>
          )}
      </div>
    )
  }


  return (
    <ul className="timeline__list">
      {(
        props.timelineHigherLoadedPage === 1
        && (props.type === 1 || props.type === 2)
        && !props.contextMsgId
        && (
          (props.auxiliaryHasArchive && props.activeChatSource === 'media' && !props.isAuxiliaryArchiveDisplayed) ||
          (props.hasArchive && props.activeChatSource === 'msgs' && !props.isArchiveDisplayed && !props.search?.length) ||
          (props.search?.length && !props.isGlobalSearch && !props.isAuxiliaryArchiveDisplayed)
        )
      ) ? (
        <div className="timeline__group">
          <button
            className="timeline__btn--archive"
            onClick={() => {
              props.updateContactTimeline(activeRecipient, null, 'up', true);
            }}
          >
            {props.search?.length ? "Search In Communication History" : "View archive"}
          </button>
        </div>
      ) : null}

      {(props.timelineHigherLoadedPage === 1
      && (props.type === 1 || props.type === 2)
      && props.search?.length
      && !props.contextMsgId
      ) ? props.isGlobalSearch 
          ? !props.isAuxiliaryArchiveDisplayed 
            && (
              <div className="timeline__group">
                <button
                  className="timeline__btn--archive"
                  onClick={() => {
                    props.updateGlobalContactMessageSearch(activeRecipient, null, 'up', true)
                  }}
                >
                  Search All History Chats
                </button>
              </div>
            ) 
          : props.isAuxiliaryArchiveDisplayed
            && (
              <div className="timeline__group">
                <button
                  className="timeline__btn--archive"
                  onClick={() => {
                    props.startGlobalMsgSearch(search, activeRecipient)
                  }}
                >
                  Search All Chats
                </button>
              </div>
            )
         : null 
      }

      

      {timeline.map(group => {

          let dateByInteractionType = 'dateCreated';

          if (group[0].type === 7) {
            dateByInteractionType = 'remind_at';
        }
        else if (group[0].type === 11) {
          dateByInteractionType = 'date';
        }


        const groupDateByTimezone = getDateByTimezoneOffset(
          props.userTimezone,
          group[0][dateByInteractionType]
        );

        return (
          <div className="timeline__group" key={groupDateByTimezone}>

            {group[0].type !== 11 &&
              <DateIndicator
                groupDate={groupDateByTimezone}
                showTimePickerForDateContext={props.showTimePickerForDateContext}
                chatType={props.type}
                contextDate={props.contextDate}
                contextDateRef={props.contextDateRef}
                userTimezone={props.userTimezone}
              />
            }

            {group.map(interaction => renderInteractions(interaction, unreadRef))}
          </div>
        )
      }
      )}
    </ul>
  )
}



export default InteractionsList;
