import API from 'api/api';

import { onUpdateTimeline, UPDATE_ACTIVE_CLIENT } from './clientChats';
import { FILTER_CLIENTS, FILTER_GIRLS, CLIENTS, getContactListPending } from './contacts';

import { onSelectTimelineOperators, groupTimelineByDate } from './roomChats';
import { UPDATE_ACTIVE_GIRL } from './girlChats';

export const STOP_CLIENT_SEARCH = 'STOP_CLIENT_SEARCH';
export const GET_SEARCHED_CLIENTS = 'GET_SEARCHED_CLIENTS';
export const UPDATE_SEARCHED_GIRLS = 'UPDATE_SEARCHED_GIRLS';
export const UPDATE_SEARCHED_CLIENTS = 'UPDATE_SEARCHED_CLIENTS';
export const STOP_GIRL_SEARCH = 'STOP_GIRL_SEARCH';
export const GET_SEARCHED_GIRLS = 'GET_SEARCHED_GIRLS';
export const SEARCH_CONTACTS = 'SEARCH_CONTACTS';
export const GET_SEARCHED_CONTACTS = 'GET_SEARCHED_CONTACTS';

export const GET_SEARCHED_MESSAGE_CONTEXT = 'GET_SEARCHED_MESSAGE_CONTEXT';

export const STOP_SEARCH_IN_CHAT = 'STOP_SEARCH_IN_CHAT';
export const STOP_SEARCH_CLIENT_MESSAGES = 'STOP_SEARCH_CLIENT_MESSAGES';
export const STOP_SEARCH_GIRL_MESSAGES = 'STOP_SEARCH_GIRL_MESSAGES';
export const GET_SEARCHED_CHAT_MESSAGES = 'GET_SEARCHED_CHAT_MESSAGES';
export const GET_SEARCHED_CLIENT_MESSAGES = 'GET_SEARCHED_CLIENT_MESSAGES';
export const GET_SEARCHED_GIRL_MESSAGES = 'GET_SEARCHED_GIRL_MESSAGES';
export const UPDATE_SEARCHED_MESSAGES = 'UPDATE_SEARCHED_MESSAGES';
export const UPDATE_SEARCHED_CLIENT_MESSAGES = 'UPDATE_SEARCHED_CLIENT_MESSAGES';
export const UPDATE_SEARCHED_GIRL_MESSAGES = 'UPDATE_SEARCHED_GIRL_MESSAGES';

export const GLOBAL_CLIENT_MESSAGE_SEARCH = 'GLOBAL_CLIENT_MESSAGE_SEARCH';
export const GLOBAL_GIRL_MESSAGE_SEARCH = 'GLOBAL_GIRL_MESSAGE_SEARCH';
export const UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH = 'UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH';
export const UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH = 'UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH';

export const STOP_GLOBAL_CLIENT_MESSAGE_SEARCH = 'STOP_GLOBAL_CLIENT_MESSAGE_SEARCH';

let myId = null;


export const stopContactsSearchByType = (activeTab) => dispatch => {
  activeTab === CLIENTS
    ? dispatch(stopClientsSearch())
    : dispatch(stopGirlsSearch());
};

export const stopClientsSearch = (status) => dispatch => {
  dispatch({
    type: STOP_CLIENT_SEARCH,
    payload: status
  });
};

export const stopGirlsSearch = (status) => dispatch => {
  dispatch({
    type: STOP_GIRL_SEARCH,
    payload: status
  });
};

export const searchContactsByType = (query, activeTab) => dispatch => {
  dispatch(getContactListPending(activeTab));

  const type = activeTab === CLIENTS ? 1 : 2;

  API.searchContacts(type, query)
    .then(res => {
      dispatch({
        type: type === 1
          ? GET_SEARCHED_CLIENTS
          : GET_SEARCHED_GIRLS,
        payload: { data: res.data, query }
      });
    })
    .catch(err => console.log(err));
};

export const updateSearchContactsByType = (type, offset, query) => (dispatch) => {
  API.searchContacts(type, query, offset)
    .then(res => {
      dispatch({
        type: type === 1
          ? UPDATE_SEARCHED_CLIENTS
          : UPDATE_SEARCHED_GIRLS,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const startContactsSearch = (status) => dispatch => {
  dispatch({
    type: SEARCH_CONTACTS,
    payload: status
  });
};

export const stopContactMessageSearch = (contact, status) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? STOP_SEARCH_CLIENT_MESSAGES
      : STOP_SEARCH_GIRL_MESSAGES,
    payload: status
  });
};

export const stopChatMessageSearch = () => dispatch => {
  dispatch({
    type: STOP_SEARCH_IN_CHAT
  });
};

export const searchMessageInChat = (query, activeChatId) => (dispatch, getState) => {
  if (!myId) {
    myId = getState().user.id;
  }

  API.searchMessageInChat(activeChatId, query)
    .then(res => {
      dispatch({
        type: GET_SEARCHED_CHAT_MESSAGES,
        payload: {
          data: groupTimelineByDate(res.data, myId),
          query
        }
      });
    })
    .catch(err => console.log(err));
};

export const searchContactMessage = (query, contact) => dispatch => {
  return API.searchContactMessage(contact.id, query)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? GET_SEARCHED_CLIENT_MESSAGES
          : GET_SEARCHED_GIRL_MESSAGES,
        payload: { data: res.data, query }
      });
    })
    .catch(err => console.log(err));
};

export const searchGlobalContactMessage = (query, contact) => dispatch => {
  API.searchContactMessage('', query)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? GLOBAL_CLIENT_MESSAGE_SEARCH
          : GLOBAL_GIRL_MESSAGE_SEARCH,
        payload: { data: res.data, query }
      });
    })
    .catch(err => console.log(err));
};

export const updateChatMessageSearch = (activeChat, offset) => (dispatch, getState) => {
  const query = getState().search.searchInChat;

  API.searchMessageInChat(activeChat.id, query, offset)
    .then(res => {
      if (!res.data.length) { return; }

      dispatch({
        type: UPDATE_SEARCHED_MESSAGES,
        payload: groupTimelineByDate(res.data, myId)
      });
    })
    .catch(err => console.log(err));
};

export const updateContactMessageSearch = (contact, offset) => (dispatch, getState) => {
  const query = contact.type === 1
    ? getState().search.searchInClientMessages
    : getState().search.searchInGirlMessages;

  API.searchContactMessage(contact.id, query, offset)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? UPDATE_SEARCHED_CLIENT_MESSAGES
          : UPDATE_SEARCHED_GIRL_MESSAGES,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const updateGlobalContactMessageSearch = (contact, offset) => (dispatch, getState) => {
  const query = contact.type === 1
    ? getState().search.searchInClientMessages
    : getState().search.searchInGirlMessages;

  API.searchContactMessage('', query, offset)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH
          : UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const searchContacts = (query) => dispatch => {
  return API.searchContacts(0, query, 0, 10)
    .then(res => {
      dispatch({
        type: GET_SEARCHED_CONTACTS,
        payload: res.data
      })
        ;
      return res.data;
    })
    .catch(err => console.log(err));
};

export const getMessageContext = message => dispatch => {
  if (!message) {
    dispatch({
      type: GET_SEARCHED_MESSAGE_CONTEXT,
      payload: null
    });

    return;
  }

  API.getMessageContext(message.id)
    .then(res => {
      dispatch({
        type: GET_SEARCHED_MESSAGE_CONTEXT,
        payload: {
          message,
          data: res.data,
        }
      });
    })
    .catch(console.error);
};


const initialState = {
  searchData: [],
  searchedContacts: [],
  searchedClientMessages: null,
  searchedGirlMessages: null,
  searchedChatMessages: [],
  searchedContactsInTabs: [],
  messageContext: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FILTER_CLIENTS:
    case STOP_CLIENT_SEARCH: {
      return {
        ...state,
        searchClients: false,
        searchedContactsInTabs: []
      };
    }

    case FILTER_GIRLS:
    case STOP_GIRL_SEARCH: {
      return {
        ...state,
        searchGirls: false,
        searchedContactsInTabs: []
      };
    }

    case GET_SEARCHED_CLIENTS: {
      return {
        ...state,
        searchClients: payload.query,
        searchedContactsInTabs: payload.data
      };
    }

    case GET_SEARCHED_GIRLS: {
      return {
        ...state,
        searchGirls: payload.query,
        searchedContactsInTabs: payload.data
      };
    }

    case UPDATE_SEARCHED_CLIENTS:
    case UPDATE_SEARCHED_GIRLS: {
      return {
        ...state,
        searchedContactsInTabs: [...state.searchedContactsInTabs, ...payload]
      };
    }

    case SEARCH_CONTACTS: {
      return {
        ...state,
        searchContacts: false,
        searchedContacts: []
      };
    }

    case GET_SEARCHED_CONTACTS: {
      return {
        ...state,
        searchContacts: true,
        searchedContacts: payload
      };
    }

    case UPDATE_ACTIVE_CLIENT: {
      return {
        ...state,
        searchInClientMessages: payload.isGlobalMsgSearch
          ? state.searchInClientMessages
          : null,
        searchedClientMessages: null,
        messageContext: null
      };
    }

    case STOP_SEARCH_CLIENT_MESSAGES: { //stop search
      return {
        ...state,
        searchInClientMessages: payload,
        searchedClientMessages: payload ? state.searchedClientMessages : null,
        messageContext: payload ? state.messageContext : null
      };
    }

    case GLOBAL_CLIENT_MESSAGE_SEARCH:
    case GET_SEARCHED_CLIENT_MESSAGES: {
      return {
        ...state,
        searchInClientMessages: payload.query,
        searchedClientMessages: payload.data
      };
    }

    case UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH:
    case UPDATE_SEARCHED_CLIENT_MESSAGES: {
      return {
        ...state,
        searchedClientMessages: onUpdateTimeline(state.searchedClientMessages, payload)
      };
    }

    case UPDATE_ACTIVE_GIRL: {
      return {
        ...state,
        searchInGirlMessages: payload.isGlobalMsgSearch
          ? state.searchInGirlMessages
          : null,
        searchedGirlMessages: null,
        messageContext: null
      };
    }

    case STOP_SEARCH_GIRL_MESSAGES: {//  stop search
      return {
        ...state,
        searchInGirlMessages: payload,
        searchedGirlMessages: payload ? state.searchedGirlMessages : null,
        messageContext: payload ? state.messageContext : null
      };
    }

    case GLOBAL_GIRL_MESSAGE_SEARCH:
    case GET_SEARCHED_GIRL_MESSAGES: {
      return {
        ...state,
        searchInGirlMessages: payload.query,
        searchedGirlMessages: payload.data
      };
    }

    case UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH:
    case UPDATE_SEARCHED_GIRL_MESSAGES: {
      return {
        ...state,
        searchedGirlMessages: onUpdateTimeline(state.searchedGirlMessages, payload)
      };
    }

    case STOP_SEARCH_IN_CHAT: {
      return {
        ...state,
        searchInChat: false,
      };
    }

    case GET_SEARCHED_CHAT_MESSAGES: {
      return {
        ...state,
        searchInChat: payload.query,
        searchedChatMessages: onSelectTimelineOperators([], payload.data, myId)
      };
    }

    case UPDATE_SEARCHED_MESSAGES: {
      return {
        ...state,
        searchedChatMessages: onSelectTimelineOperators(state.searchedChatMessages, payload, myId)
      };
    }

    case GET_SEARCHED_MESSAGE_CONTEXT: {
      return {
        ...state,
        messageContext: payload
      };
    }

    default:
      return state;
  }
};