import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import Tooltip from '../../../../../../components/UI/Tooltip/Tooltip';

import './Marker.scss';
import Circle from '../Circle/Circle';
import ICONS from 'assets/icons';
import { classModifier } from 'utils';


const Marker = (props) => {
  const {
    lng,
    lat,
    children,
    tooltip,
    className,
    radius,
    zoom,
    selected,
    extraTooltipCondition,
    onClick,
    ...restProps
  } = props;

  const classes = classNames(
    className,
    classModifier('geo-marker', onClick && 'clickable'),
  );

  return (
    <div
      className={classes}
      key={extraTooltipCondition ? 'extra' : 'default'}
      onClick={onClick}
      {...restProps}
    >
      <Tooltip
        className="geo-marker__tooltip"
        text={tooltip}
        interactive
        extraCondition={extraTooltipCondition}
      >
        <ICONS.marker className='geo-marker__svg' />
      </Tooltip>

      <div className="geo-marker__content">
        {children}
      </div>

      {selected && <ICONS.marker className='geo-marker__selected-svg' />}

      {!!radius && (
        <Circle
          className="geo-marker__circle"
          lat={lat}
          lng={lng}
          radius={radius}
          zoom={zoom}
        />
      )}
    </div>
  )
}

export default Marker;
