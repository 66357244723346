import { useRef } from 'react';

const useBuildNavigation = () => {
  const mapRef = useRef(null);
  const directionsRenderer = useRef(null);

  const initializeNavigation = (map) => {
    mapRef.current = map;
  }

  const buildNavigation = (originPoint, destinationPoint) => {
    const directionsService = new window.google.maps.DirectionsService();
    directionsRenderer.current = new window.google.maps.DirectionsRenderer({ suppressMarkers: true });
    directionsRenderer.current.setMap(mapRef.current);

    return new Promise((resolve, reject) => {
      directionsService.route(
        {
          origin: { lat: originPoint.latitude, lng: originPoint.longitude },
          destination: { lat: destinationPoint.latitude, lng: destinationPoint.longitude },
          travelMode: window.google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.current.setDirections(result);
            resolve(result);
          } else {
            console.error(`error fetching directions ${result}`);
            reject(status);
          }
        }
      );
    })
  }

  const resetNavigation = () => {
    if (directionsRenderer.current) {
      directionsRenderer.current?.setMap(null);
      directionsRenderer.current = null;
    }
  }

  return {
    initializeNavigation,
    buildNavigation,
    resetNavigation
  }
}

export default useBuildNavigation;
