import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import API from 'api/api';
import { CONTACT_TYPES, ROUTES } from 'config/constants';
import { getContactsById } from 'redux/ducks/contacts';
import { updateActiveContact } from 'redux/ducks/clientChats';
import { closeModal } from 'redux/ducks/activeWindows';

import TypingIndicator from 'components/UI/TypingIndicator/TypingIndicator';
import ICONS from 'assets/icons';


const GirlsMarkerTooltip = (props) => {
  const {
    girlInformation,
    clientPosition,
    buildNavigationPathToGirl,
    setShouldCloseTooltip,
  } = props;

  const { latitude, longitude } = clientPosition;

  const [girlData, setGirlData] = useState(null);
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { caller_id } = girlInformation;
  const shouldShowDistanceButton = latitude && longitude;

  const handleOpenGirlChat = () => {
    dispatch(getContactsById(caller_id, CONTACT_TYPES.GIRL))
      .then((contact) => {
        dispatch(updateActiveContact(contact, true));
        setShouldCloseTooltip(true);
        dispatch(closeModal());

        if (location.pathname !== ROUTES.main) {
          navigate(ROUTES.main);
        }
      });
  };

  const handleBuildNavigationPathToGirl = () => {
    setShouldCloseTooltip(true);
    buildNavigationPathToGirl()
      .finally(() => setShouldCloseTooltip(false));
  }

  useEffect(() => {
    if (clientPosition.latitude === null || clientPosition.longitude === null) return;

    setPending(true);

    API.getDistanceToGirl(caller_id, latitude, longitude)
      .then(({ data }) => setGirlData(data))
      .catch(console.error)
      .finally(() => setPending(false));
  }, [caller_id, latitude, longitude]);

  return (
    <div className="girls-map__tooltip">
      <header className="girls-map__tooltip-header">
        <span>
          {pending
            ? <TypingIndicator />
            : (girlData?.distance && `${girlData?.distance} miles`)
          }
        </span>
        
        <div className="girls-map__tooltip-buttons">
          <button onClick={handleOpenGirlChat}>
            <ICONS.comments />
          </button>

          {shouldShowDistanceButton && (
            <button onClick={handleBuildNavigationPathToGirl}>
              <ICONS.mapDash />
            </button>
          )}
        </div>
      </header>

      <div className='girls-map__tooltip-content'>
        {girlInformation.fn}
      </div>
    </div>
  )
}

export default React.memo(
  GirlsMarkerTooltip,
  (prevProps, nextProps) => {
    return prevProps.girlInformation.caller_id === nextProps.girlInformation.caller_id;
  }
);
