import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import Highlighter from "react-highlight-words";

import { parseMsgText, classModifier } from 'utils';
import Tooltip from '../../../../../components/UI/Tooltip/Tooltip';
import { CONTACT_TYPES, GEOLOCATION_SERVICES_NAMES } from 'config/constants';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

const MsgText = ({ interaction, highlightedText, ...props }) => {
  const dispatch = useDispatch();

  const handleGirlNameClick = (e) => {
    if (props.onGirlNameClick && typeof props.onGirlNameClick === 'function') {
      props.onGirlNameClick(e);
    }
    return;
  }

  const highlightIfNeed = (text, highlightedText) => highlightedText
    ? <Highlighter
      highlightClassName="interaction__highlight"
      searchWords={highlightedText.split(' ')}
      autoEscape={true}
      textToHighlight={text}
    />
    : text;

  const getMsgTextLayout = () => {
    if (interaction.hasUrls) {
      const parsedArray = parseMsgText(interaction.body || interaction.description);
      const parsedMsg = [];
      const attachmentLinksCopy = [...(interaction.attachments?.links || [])];

      // if parseRegex find no match. Also it mean that server not parse msg correct;
      if (typeof parsedArray === 'string') {
        console.warn('Custom client error: Server bad parse interaction with id: ', interaction.id)
        return highlightIfNeed(parsedArray, highlightedText);
      }


      parsedArray.forEach((part, idx) => {
        switch (part.type) {
          case 'text':
            parsedMsg.push(highlightIfNeed(part.value, highlightedText));
            break;
          case 'mention':
            parsedMsg.push(
              <span
                className="interaction__mention"
                key={idx}
                data-value={part.value}
              >
                {highlightIfNeed(part.value, highlightedText)}
              </span>
            );
            break;
          case 'link':
            const attachemntLink = attachmentLinksCopy.shift();
            const isClientMessage = interaction.contact?.type === CONTACT_TYPES.CLIENT;
            const locationSiteTitle = Object.values(GEOLOCATION_SERVICES_NAMES).find(
              service => attachemntLink?.site_title?.match(new RegExp(service))
            );
            const isLocationLink = !!locationSiteTitle;
            const locationCoordinates = part.value?.match(/[-+]?\d{1,3}\.\d+/g)?.map(Number);
            const isLocationLinkWithPoint = locationCoordinates?.length === 2;
            const shouldBeOpennedInMap = isLocationLink && isClientMessage && isLocationLinkWithPoint;

            const handleLocationClick = (event) => {
              event.preventDefault();

              dispatch(openModal(MODAL_TYPES.girlsMap, {
                clientLocation: { latitude: locationCoordinates[0], longitude: locationCoordinates[1] },
                mapService: locationSiteTitle,
              }));
            }

            parsedMsg.push(
              <a
                className="interaction__link"
                href={part.value}
                target="_blank"
                rel="noopener noreferrer"
                key={idx}
                {...(shouldBeOpennedInMap && { onClick: handleLocationClick })}
              >
                {highlightIfNeed(part.value, highlightedText)}
              </a>
            );
            break;
          case 'girlName':
            parsedMsg.push(
              <span
                className="interaction__clickable-name"
                data-value={part.value}
                key={idx}
                onClick={handleGirlNameClick}
              >
                {highlightIfNeed(part.value, highlightedText)}
              </span>
            );
            break;

          default:
            break;
        }
      });
      return parsedMsg;

    }
    else {
      return highlightIfNeed(parseMsgText(interaction.body || interaction.description, true), highlightedText)
    }
  }

  return (
    <p
      className={classModifier('interaction__text', [
        ((interaction.is_deleted || interaction.deleted) && !interaction.attachments) && 'deleted',
        props.isMsgExpanded && 'expanded',
        props.isCompleted && 'completed',
        props.mode,
      ])}>
      {getMsgTextLayout(interaction)}
    </p>
  );
}

export default memo(MsgText);
