import { convertToAudio, LS } from "utils";

import { SET_USER_INFO } from "./user";

export const UPDATE_USER_LOCAL_SETTINGS = 'UPDATE_USER_LOCAL_SETTINGS';
export const UPDATE_NOTIFICATIONS_VOLUME = 'UPDATE_NOTIFICATIONS_VOLUME';
export const UPDATE_TAG_COLORS = 'UPDATE_TAG_COLORS';

export const NOTIFICATIONS_VOLUME_TYPE = {
  ALL: 'notificationsVolume',
  CALL: 'call',
  SMS: 'sms',
  BOOKING: 'booking',
  CLIENT_SMS: 'clientSms'
};

export const updateUserLocalSettings = (settings, userId) => dispatch => {
  LS.updateUserStorage(settings, userId);

  return dispatch({
    type: UPDATE_USER_LOCAL_SETTINGS,
    payload: settings
  })
}

export const updateNotificationsVolume = (volume, type, userId) => ({
  type: UPDATE_NOTIFICATIONS_VOLUME,
  payload: {
    type,
    volume,
    userId,
  }
});

export const updateTagColors = (tags) => ({
  type: UPDATE_TAG_COLORS,
  payload: tags
});

const initialState = {
  agent: {},
  client: {},
  girl: {},
  service: {},
  operator: {},
  tagColors: {},
  callBeep: null,
  showUserModesAtLogin: false,
  volume: {
    call: 100,
    sms: 100,
    clientSms: 100,
    booking: 100,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO: {
      const { settings } = action.payload;

      return {
        ...state,
        pending: false,
        agent: convertURLsToAudio(settings.agent),
        client: convertURLsToAudio(settings.client),
        girl: convertURLsToAudio(settings.girl),
        service: convertURLsToAudio(settings.service),
        operator: convertURLsToAudio(settings.operator),
        callBeep: convertToAudio(settings.callBeep, true),
        showUserModesAtLogin: !!action.payload['showUserModesAtLogin']
      };
    }

    case UPDATE_USER_LOCAL_SETTINGS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case UPDATE_NOTIFICATIONS_VOLUME: {
      //set initial volume from old value
      if (action.payload.type === NOTIFICATIONS_VOLUME_TYPE.ALL && typeof action.payload.volume === 'number') {
        return {
          ...state,
          volume: {
            call: action.payload.volume,
            sms: action.payload.volume,
            booking: action.payload.volume,
          },
        };
      }
      //set initial volume
      else if (action.payload.type === NOTIFICATIONS_VOLUME_TYPE.ALL) {
        return {
          ...state,
          volume: action.payload.volume,
        };
      }

      // update volume
      const updatedVolume = {
        ...state.volume,
        [action.payload.type]: action.payload.volume,
      };

      LS.setItem(NOTIFICATIONS_VOLUME_TYPE.ALL, updatedVolume, action.payload.userId);

      return {
        ...state,
        volume: updatedVolume,
      };
    }

    case UPDATE_TAG_COLORS: {
      return {
        ...state,
        tagColors: action.payload.reduce((acc, tag) => ({ ...acc, [tag.id]: tag.color }), {}),
      }
    }

    default:
      return state;
  }
};

const convertURLsToAudio = (ringtonesObj) => {
  const ringtones = {
    sms: {
      ...ringtonesObj.sms,
      sms: convertToAudio(ringtonesObj.sms, false),
    },
    call: {
      ...ringtonesObj.call,
      call: convertToAudio(ringtonesObj.call, true),
    },
    booking: {
      ...ringtonesObj.booking,
      booking: convertToAudio(ringtonesObj.booking, true),
    },
    // important: convertToAudio('./media/sounds/important-msg.mp3', false),
  };
  return ringtones;
};
