import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import ICONS from 'assets/icons';
import { classModifier, getContactAvatar, showContactName } from 'utils';
import { closeModal, MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { getActiveDivaGirls } from 'redux/ducks/divaGirls';

import './QuickBookingGirlsListItem.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const defaultOnClick = (selectedContact, dispatch, activeRecipient, contactSessionId) => {
  const divaId = selectedContact.diva_default_id || selectedContact.diva_id;

  if (!divaId) {
    return;
  }

  dispatch(getActiveDivaGirls({ params: { 'filter-profileid': [divaId] }, quickBooking: true }))
    .then(profile => {
      if(profile) {
        dispatch(closeModal(MODAL_TYPES.SearchList));
        dispatch(openModal(MODAL_TYPES.bookingForm, {
          initialProfile: profile,
          initialActiveSessionContact: activeRecipient,
          activeSessionId: contactSessionId,
        }));
      }
    })
    .catch(console.error)
}

const QuickBookingGirlsListItem = ({ 
  activeRecipient,
  activeGirlId,
  lastGirlId,
  penultimateGirlId,
  item,
  query,
  onClick,
  contactSessionId,
}) => {
  const divaId = item.diva_default_id || item.diva_id;

  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();
  const isNotForGirl = useMemo(() => !!divaId && activeRecipient?.not_for_clients
    ?.map(i => i.diva_default_id || null)
    .includes(divaId), [activeRecipient]);

  const handleBookingFormOpen = () => {
    setIsPending(true);
    (onClick || defaultOnClick)(item, dispatch, activeRecipient, contactSessionId)
  }

  const getItemStatusColor = () => {
    let styleValue = '';

    if (!item.availability) {
      styleValue = '#000000';
    }

    if (item.availability === 'available') {
      styleValue = '#29F18E';
    }

    if (item.availability === 'off' || item.availability === 'off today') {
      styleValue = '#f8737f';
    }

    return styleValue;
  }

  const isNeedHorizontalLine =
    !query &&
    (activeGirlId === item.id ||
      lastGirlId === item.id ||
      (activeGirlId === lastGirlId && penultimateGirlId === item.id));

  return (
    <li
      className={classModifier('quick-booking-item', [
        !divaId && 'no-diva-default-id',
        isNeedHorizontalLine && 'horizontal-line',
        isPending && 'is-pending',
      ])}
      onClick={divaId && handleBookingFormOpen}
    >
      {isPending && <Spinner className="quick-booking-item__spinner"/>}
      <div
        className={classModifier('quick-booking-item__content', [
          !query && activeGirlId === item.id && !onClick && 'active-contact',
          !query && item.agentId && !onClick && 'agent',
        ])}
      >
        <div className="quick-booking-item__img-wrap">
          <LazyLoadImage src={getContactAvatar(item)} />
        </div>

        <div className="quick-booking-item__info">
          <div className="quick-booking-item__info-top">
            {isNotForGirl 
              ? <div className='quick-booking-item__icon-wrapper'><ICONS.stop /></div>
              : <div className="quick-booking-item__status-wrapper">
                  <span
                    className="quick-booking-item__status-point"
                    style={{ backgroundColor: getItemStatusColor() }}
                  />
                </div>
            }
            <span className="quick-booking-item__name">
              {showContactName(item.fn, "chat-header__ex")}
            </span>
          </div>
          {item.detailsForPopUp && (
            <div className="quick-booking-item__girl-info">
              <span>
                ￡{item.detailsForPopUp[0]} {item.detailsForPopUp[1]}
              </span>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export default QuickBookingGirlsListItem;
