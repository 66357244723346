import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import {
  getContactAvatar,
  classModifier,
  parseMsgText,
  isEmptyObj,
  showContactName,
} from 'utils';
import {
  selectContactById,
  selectIsContactActive,
  selectRelatedOperator,
  selectUserTimezone
} from 'redux/selectors/selectors';
import ICONS from 'assets/icons';
import { useToggle } from 'hooks';
import { CONTACT_TYPES, INTERACTION_TYPES } from 'config/constants';
import { startSearchContactsMsgsInChat, updateActiveContact } from 'redux/ducks/clientChats';
import { openModal, closeModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { CARD_TABS } from 'config/constants';
import { CONTACT_ITEM_CONFIG } from 'config/dates-сonfig';
import { v4 as uuid } from 'uuid';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
// import { EditContactFormNew } from 'containers/Dialogs/Dialogs'; //TODO v2
import BusyStatus from './BusyStatus/BusyStatus';
import DateTime from 'components/DateTime';
import Spinner from 'components/UI/Spinner/Spinner';
import SystemMsg from 'containers/Chat/components/ChatTimeline/interactions/SystemMsg/SystemMsg';
import Portal from 'components/Portal';
import { CONTACTS_LIMIT, getContactsById, updateContact } from '../../../../redux/ducks/contacts';
import BookingIcon from 'components/BookingIcon';
import API from 'api/api';
import SearchListContactItem from 'components/SearchList/components/SearchListContactItem/SearchListContactItem';
import getIconTypeByInteractionId from 'utils/getIconTypeByInteractionId';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import FloatingMenu from 'components/UI/FloatingMenu/FloatingMenu';
import ContactTags from 'components/ContactTags/ContactTags';
import { SPECIAL_TAG_KEYS } from 'components/ContactTags/config/config';

const ContactItem = (props) => {
  const {
    contact = {},
    style,
    isActive,
    isBusy,
    dividedContactId,
    girlBookingDetails,
    isFromListOfGirlWithStatus,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const girlBookingsInfo = girlBookingDetails.find(el => el.girlId === props.id);

  const {
    INCOMING_MSG,
    INCOMING_CALL,
    INCOMING_MSG_TELEGRAM,
    INCOMING_MSG_WHATSAPP,
    INCOMING_MSG_IPHONE_IMESSAGE,
    INCOMING_MSG_IPHONE_SMS,
    INCOMING_EMAIL,
  } = INTERACTION_TYPES;

  // const isLastMsgFormTelegram = !!contact.interactionObject && (
  //   contact.interactionObject.type === 9 || contact.interactionObject.type === 10
  // );

  // const isLastMsgFormWhatsapp = !!contact.interactionObject && (
  //   contact.interactionObject.type === 18 || contact.interactionObject.type === 19
  // );

  // const isLastMsgFormIphone = !!contact.interactionObject && (
  //   contact.interactionObject.type === 20 || contact.interactionObject.type === 21 ||
  //   contact.interactionObject.type === 22 || contact.interactionObject.type === 23
  // );
  const lastInteractionType = getIconTypeByInteractionId(contact.interactionObject?.type);
  const LastInteractionIcon = ICONS[lastInteractionType];

  const [isBusyTooltipShowed, setBusyTooltipShowing] = useToggle(false);

  const isTemporary = contact.callerTags?.some(tag => tag.title === 'Temporary') || !!contact.isTemporary;
  const isGirlContactItem = contact.type === 2;
  const contactName = isGirlContactItem 
    ? `${contact.short_name || ''} ${contact.agentName || ''}` 
    : contact.fn;

  const handleContactClick = (e) => {
    e.preventDefault();

    props.getContactsById(contact.id, contact.type)
      .then((contact) => {
        !isActive && props.updateActiveContact(contact);
      });

    const chatSearch = props.type === CONTACT_TYPES.CLIENT
      ? props.clientsChatSearch
      : props.girlsChatSearch;

    // if we search by msgs (global) and search contact is active (opened chat with it),
    // we need to start search inside the contactsChat
    if (isActive && props.searchSource === 'msgs' && !!props.search && chatSearch !== props.search) {
      props.startSearchContactsMsgsInChat(props.type, props.search);
    }
  };

  const onContextMenu = (e) => {
    e.preventDefault();

    return props.isContextMenu
      ? props.openContextMenu([e.clientX, e.clientY], contact)
      : null;
  };

  // Show count when description is closed
  const showUnreadCount = () => {
    const isRelatedUser = !!contact.relatedUserId && contact.relatedUserId !== props.userId;

    if (contact.unreadCount > 0) {
      return (
        <span className={
          contact.withImportantUnread
            ? 'contact-item__count contact-item__count--important'
            : classModifier('contact-item__count', isRelatedUser && 'related')
        }>
          {contact.unreadCount >= 100 ? '99+' : contact.unreadCount}
        </span>
      )
    }
  }

  const busyStatus = isBusy
    ? <span className="contact-item__busy"
      onMouseEnter={() => setBusyTooltipShowing()}
      onMouseLeave={() => setBusyTooltipShowing()}
    ></span>
    : null;

  const renderRelatedOperator = () => {
    if (!contact.relatedUserId && !contact.lastOperator) {
      return null;
    }

    let name;

    if (contact.relatedUserId === props.userId) {
      name = 'You'
    } else if (!contact.relatedUserId && contact.lastOperator?.id === props.userId) {
      name = 'released by you';
    } else if (props.relatedOperator) {
      name = props.relatedOperator.username;
    } else {
      name = `released by ${contact.lastOperator?.username}`;
    }

    return <span className="contact-item__related">{name}</span>;
  };

  const handleEdit = (event) => {
    event.stopPropagation();

    props.openModal(MODAL_TYPES.contactCard, {
      contact,
      defaultTab: CARD_TABS.EDIT,
      autoFocusInput: 'fn',
    })
  };

  const renderLastInteraction = () => {
    let lastInteraction = '';

    const interactionObject = props.searchSource === 'msgs' && !!contact.interactionObject_searched && !!props.search
      ? contact.interactionObject_searched
      : contact.interactionObject;

    switch (interactionObject.type) {
      case 1: {
        lastInteraction = 'Incoming call';
        break;
      }
      case 2: {
        lastInteraction = 'Outgoing call';
        break;
      }
      case 3: {
        lastInteraction = interactionObject.message;
      }
      case 4: {
        if (interactionObject.user_id === props.userId) {
          lastInteraction = 'You: ' + interactionObject.message;
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 8: {
        // // we don't need parse msg on attachment if it's system msg
        return <SystemMsg
          interaction={interactionObject}
          returnOnlyMsgString={true}
          userHour12={props.userHour12}
        />
      }
      case 9: {
        if (interactionObject.attachments?.hasOwnProperty('voiceMsg')) {
          lastInteraction = 'Incoming voice message'
          break;
        }
        if (
          interactionObject.attachments?.hasOwnProperty('images') &&
          interactionObject.attachments?.hasOwnProperty('videos')
        ) {
          lastInteraction = 'Incoming media'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('videos')) {
          lastInteraction = 'Incoming video'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('images')) {
          lastInteraction = 'Incoming image'
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 10: {
        if (interactionObject.attachments?.hasOwnProperty('voiceMsg')) {
          lastInteraction = 'Outgoing voice message'
          break;
        }
        if (
          interactionObject.attachments?.hasOwnProperty('images') &&
          interactionObject.attachments?.hasOwnProperty('videos')
        ) {
          lastInteraction = 'Outgoing media'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('videos')) {
          lastInteraction = 'Outgoing video'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('images')) {
          lastInteraction = 'Outgoing image'
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 12: {
        lastInteraction = 'Incoming email'
        break;
      }
      default:
        lastInteraction = interactionObject.message;
    }

    return parseMsgText(lastInteraction, true);
  };

  const fetchSearchClientsContacts = ({ query, offset, cancelToken }) => {
    return API.searchContacts(1, query, offset, CONTACTS_LIMIT, cancelToken)
      .then(({ data }) => ({
        newItems: data,
        newHasMore: data.length === CONTACTS_LIMIT,
      }))
      .catch(console.error);
  };

  const onAddClient = () => {
    props.openModal(MODAL_TYPES.searchList, {
      fetchData: fetchSearchClientsContacts,
      itemComponent: SearchListContactItem,
      onChoose: async (selectedContact) => {
        setIsLoading(true)
        try {
          const preparedContact = {
            ...selectedContact,
            tels: [
              ...selectedContact.tels,
              {
                default: 0,
                labelName: 'default',
                uuid: uuid(),
                tel: contact.fn,
              },
            ]
          }

          await API.mergeContactCards(preparedContact, contact.id)

          props.openModal(MODAL_TYPES.contactCard, {
            contact: preparedContact,
            defaultTab: CARD_TABS.EDIT,
            autoFocusInput: 'fn',
          });
          props.closeModal(MODAL_TYPES.SearchList);
        } catch (error) {
          console.error(error)
        }
        setIsLoading(false)
      },
    })
  }

  const addNewContactItems = [
    {
      text: 'Add new contact',
      action: handleEdit,
    },
    {
      text: 'Add to existing contact',
      action: onAddClient,
    },
  ]

  if (isEmptyObj(contact)) {
    return (
      <div className="contact-item contact-item--pending">
        <Spinner spinnerSize="32px" />
      </div>
    )
  }

  return (
    <div
      className="contact-item__wrapper"
      style={style}
    >
      {contact.type === CONTACT_TYPES.GIRL && !!girlBookingsInfo &&
        <div className="contact-item__bookings-block">
          <ICONS.eye className="contact-item__booking-icon-eye" />
          <span className="contact-item__bookings-count">{girlBookingsInfo?.bookingsCount}</span>
        </div>
      }
      
      <FloatingMenu
        items={props.generateContextItems(contact)}
        trigger='rightClick'
      >
        <div
          id={props.id}
          className={classModifier('contact-item', [
            contact.marked && !props.isSimpleView && 'marked',
            contact.availability && contact.availability === 'off today'
              ? 'off-today'
              : contact.availability,
            contact.agentId && 'with-agent',
            isActive && 'active',
            !!contact.unreadCount && 'unread-msgs',
            contact.type === 2 && 'girl',
            dividedContactId === contact.id && 'divided',
          ])}
          onClick={handleContactClick}
        >
          <div className="contact-item__dividing-line" />

          <div
            className={`contact-item__img-wrap ${contact.blocked ? "blocked" : ""} ${contact.is_active_chat ? "contact-item__img-wrap--active" : ""
              }`}
          >
            <LazyLoadImage src={getContactAvatar(contact)} alt="ava" className="contact-item__img" />
            {busyStatus}

            {contact?.has_telegram_groups &&
              <ICONS.users className="contact-item__telegram-group-icon" />
            }

            {LastInteractionIcon && (
              <LastInteractionIcon className={classModifier("contact-item__icon", lastInteractionType)} />
            )}
          </div>

          <div className="contact-item__content-wrap">
            <div className="contact-item__info">
              {Boolean(contact.closestBooking) && (
                <BookingIcon
                  className="contact-item__booking-icon"
                  booking={contact.closestBooking}
                  userTimezone={props.userTimezone}
                />
              )}

              {Boolean(contact.todayBookingsCount) && (
                <mark className="contact-item__unfinished-bookings-count">{contact.todayBookingsCount}</mark>
              )}

              <Tooltip text={contact.fn}>
                <h4 className="contact-item__name">
                  <ContactTags
                    callers={contact}
                    tagClassName="contact-item__tag"
                    selectedSpecificTags={SPECIAL_TAG_KEYS.DEBTPRICE}
                  />

                  {showContactName(contactName, 'contact-item__ex', contact.fn)}

                  {/* {contact.type === CONTACT_TYPES.GIRL &&
                    <>
                      {!!contact.rates &&
                        <span className="contact-item__rates">
                          {contact.rates}
                        </span>
                      }

                      {!!contact.rating &&
                        <span className="chat-header__rating">
                          ({contact.rating})
                        </span>
                      }
                    </>
                  } */}
                </h4>
              </Tooltip>

              {contact.last_interaction && 
                <DateTime
                  className="contact-item__time"
                  date={isFromListOfGirlWithStatus ? contact.statusUpdateDate : contact.last_interaction}
                  config={CONTACT_ITEM_CONFIG}
                  relative={!!contact.statusUpdateDate}
                />
              }
            </div>

            <div className="contact-item__bottom">
              {contact.draft_message &&
                <ICONS.pencil className="contact-item__draft-icon" title="draft" />
              }

              <div
                className={`contact-item__last-msg ${contact.interactionObject
                  ? ([INCOMING_MSG, INCOMING_CALL, INCOMING_MSG_TELEGRAM, INCOMING_MSG_WHATSAPP, 
                      INCOMING_EMAIL, INCOMING_MSG_IPHONE_IMESSAGE, INCOMING_MSG_IPHONE_SMS]
                      .includes(contact.interactionObject.type)
                    && 'contact-item__last-msg--operator')
                  : ''
                  }`
                }
              >
                {(contact.interactionObject || contact.interactionObject_searched) &&
                  renderLastInteraction()
                }
              </div>

              {renderRelatedOperator()}

              {contact.isScheduledMsg &&
                <ICONS.checkbox className="contact-item__schedule-msg-icon" />
              }

              {isTemporary &&
                <FloatingMenu
                  items={addNewContactItems}
                  trigger='click'
                >
                  <button className='contact-item__edit-contact-btn'>
                    <ICONS.plusCircle className='contact-item__edit-contact-btn-icon' />
                  </button>
                </FloatingMenu>
              }
            </div>

            {!props.isSimpleView && contact.pinned &&
              <ICONS.pin className="contact-item__pinned-icon" title="pinned" />
            }

            {showUnreadCount()}

            {isBusyTooltipShowed &&
              <BusyStatus id={props.id} isContact />
            }
          </div>

          {/* {contact.type === CONTACT_TYPES.GIRL && !!girlBookingsInfo &&
            <Portal>
              <div
                className={classModifier('contact-item__bookings-block', [
                  isContactItemHidden() && 'hidden'
                ])}
                ref={setTooltipRef}
                {...getTooltipProps()}
              >
                <ICONS.eye className="contact-item__booking-icon-eye" />
                <span className="contact-item__bookings-count">{girlBookingsInfo?.bookingsCount}</span>
              </div>
            </Portal>
          } */}

          {isLoading && (
            <Portal>
              <div className="contact-item--compare-clients-tels-spinner">
                <Spinner spinnerSize="32px" />
              </div>
            </Portal>
          )
          }
        </div >
      </FloatingMenu>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  contact: selectContactById(state, ownProps.id),
  isActive: selectIsContactActive(state, ownProps.type, ownProps.id),
  userId: state.user.id,
  userTimezone: selectUserTimezone(state),
  // agent: selectAgentContactById(state, ownProps.id),
  relatedOperator: selectRelatedOperator(state, ownProps.id),
  userHour12: state.user.hour12,
  girlBookingDetails: state.clientChats.bookingDetails,
});

const mapDispatchToProps = {
  getContactsById,
  updateActiveContact,
  startSearchContactsMsgsInChat,
  openModal,
  closeModal,
  updateContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactItem);
