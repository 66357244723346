import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faVideo, faImage } from '@fortawesome/free-solid-svg-icons';

import { selectOperatorByUserId, selectTelegramGroupMember } from 'redux/selectors/selectors';
import { getContactAvatar } from 'utils';
import { MESSAGE_DATE_CONFIG } from 'config/dates-сonfig';

import MsgText from '../ChatTimeline/interactions/MsgText';
import ICONS from 'assets/icons';
import DateTime from 'components/DateTime';

const AttachmentRepliedMessage = ({ interaction, profileId, activeRecipient, ...props }) => {
  const operator = useSelector(state => selectOperatorByUserId(state, interaction.user_id, profileId));
  const telegramGroupSender = props.activeGroup
    ? useSelector((state) => selectTelegramGroupMember(state, interaction.from_telegram_user_id))
    : null;

  const getSender = () => {
    let sender;
    let senderName;

    if (operator) {
      sender = operator;
      senderName = operator.id === profileId ? 'You' : operator.username;
    } else {
      sender = activeRecipient || {};
      senderName = activeRecipient?.fn || 'Unknown';
    }

    return { sender, senderName };
  };

  const replyBtnClickHandler = () => {
    props.getMessageContext(interaction.id, activeRecipient, '');
  };

  const { sender, senderName } = getSender();

  return (
    <div className={`${props.className ? props.className : ""} attachment-reply`}>
      <div className="attachment-reply__header">
        {!props.activeGroup && (
          <button className="attachment-reply__reply-btn"
                  type="button"
                  onClick={replyBtnClickHandler}
          >
            <ICONS.replyText/>
          </button>
        )}

        <div className="attachment-reply__ava">
          {telegramGroupSender?.photo
            ? <img src={`data:image/jpeg;base64,${telegramGroupSender.photo}`} alt="ava"/>
            : <img src={getContactAvatar(sender)} alt="ava"/>
          }
        </div>

        <div>
          <p className="attachment-reply__name">
            {props.activeGroup
              ? (telegramGroupSender?.first_name || 'Unknown User')
              : senderName
            }
          </p>

          <DateTime
            className="attachment-reply__time"
            date={interaction.dateCreated}
            config={MESSAGE_DATE_CONFIG}
          />
        </div>
      </div>

      <div className="attachment-reply__content">
        {interaction.body && <MsgText interaction={interaction}/>}

        {interaction.attachments &&
          <div className="attachment-reply__attachments">
            {interaction.attachments?.videos && <FontAwesomeIcon icon={faVideo}/>}
            {interaction.attachments?.images && <FontAwesomeIcon icon={faImage}/>}
            {interaction.attachments?.voiceMsg && <FontAwesomeIcon icon={faMicrophone}/>}
          </div>
        }
      </div>
    </div>
  );
};

export default AttachmentRepliedMessage;
